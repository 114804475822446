import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import ActivityCardFooter from "components/feed/FeedCard/base/ActivityCardFooter";
import ActivityCardHeader from "components/feed/FeedCard/base/ActivityCardHeader";
import MediaDisplay from "components/feed/FeedCard/base/MediaDisplay";
import PostUrlUnfurlerator from "components/feed/FeedCard/base/PostUrlUnfurlerator";
import Markdown from "components/markdown/Markdown";
import Truncate from "components/shared/Truncate";
import { FeedActivity } from "lib/motorcade/types";

function getPendoClass(audience: string, isPinned: boolean) {
  if (isPinned) return "pendo_web-group-pinned-post-click";

  switch (audience) {
    case "general":
      return "pendo_web-post-everyone";

    case "group":
      return "pendo_web-group-post";

    case "profile":
      return "pendo_web-post-followers";

    default:
      return "pendo_web-post-everyone";
  }
}

type Props = {
  activity: FeedActivity & {
    verb: "post";
  };
  isPinned: boolean;
};

export default function PostCard({ activity, isPinned }: Props) {
  const { audience } = activity;

  const pendoClass = getPendoClass(audience, isPinned);
  const truncateAmountForCarouselCard = activity?.mediaType ? 2 : 9;

  return (
    <Card className={pendoClass} sx={{ height: "100%" }}>
      <ActivityCardHeader isPinned actionText="posted to" activity={activity} />
      <CardContent sx={{ px: 3 }}>
        <Truncate
          expandable={!isPinned}
          maxLines={isPinned ? truncateAmountForCarouselCard : 3}
        >
          <Typography variant="body2">
            <Markdown markdown={activity?.object?.data?.body} />
          </Typography>
        </Truncate>

        <MediaDisplay activity={activity} isPinned={isPinned} />
        {!isPinned && (
          <PostUrlUnfurlerator max={1} text={activity?.object?.data?.body} />
        )}
      </CardContent>
      {!isPinned && <ActivityCardFooter activity={activity} />}
    </Card>
  );
}
