import { useContext, useState } from "react";

import { StreamContext } from "lib/motorcade/providers/stream";

export function useFileUploader() {
  const { streamClient } = useContext(StreamContext);
  const [isUploadingFile, setIsUploadingFile] = useState(false);

  const uploadFile = async (file: File) => {
    setIsUploadingFile(true);

    let fileUrl: string;
    const fileType = file.type;

    if (fileType === "image") {
      const { file: uploadedFile } = await streamClient.images.upload(file);
      fileUrl = uploadedFile;
    } else {
      const { file: uploadedFile } = await streamClient.files.upload(file);
      fileUrl = uploadedFile;
    }

    setIsUploadingFile(false);

    return {
      url: fileUrl,
      mediaType: file.type,
    };
  };

  return {
    uploadFile,
    isUploadingFile,
  };
}
