import {
  GENERAL_FEED_ICON,
  GROUP_ICON,
  PRIVATE_GROUP_ICON,
  PROFILE_FEED_ICON,
} from "lib/feed/constants";
import { Activity } from "lib/motorcade/types";

const audienceIcons = {
  general: <GENERAL_FEED_ICON sx={{ fontSize: "16px" }} />,
  group: <GROUP_ICON sx={{ fontSize: "16px" }} />,
  profile: <PROFILE_FEED_ICON sx={{ fontSize: "16px" }} />,
};

type Props = {
  isPrivate?: boolean;
} & Pick<Activity, "audience">;

export default function AudienceIcon({ audience, isPrivate = false }: Props) {
  if (isPrivate) return <PRIVATE_GROUP_ICON sx={{ fontSize: "16px" }} />;
  if (!audience) return null;
  return audienceIcons[audience?.toLowerCase()];
}
