import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Theme,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import { Stack } from "@mui/system";
import { RefObject, useRef, useState } from "react";

import Avatar from "components/shared/Avatar";
import Editor, { EditorViewRef } from "components/shared/inputs/Editor";
import InsertLinkButton from "components/shared/inputs/Editor/buttons/InsertLinkButton";
import LinkForm from "components/shared/inputs/Editor/forms/LinkForm";
import {
  clearAllContent,
  docHasContent,
  getContentAsMarkdown,
} from "components/shared/inputs/Editor/helpers";
import autoLink from "components/shared/inputs/Editor/plugins/autoLink";
import editLink from "components/shared/inputs/Editor/plugins/editLink";
import placeholder from "components/shared/inputs/Editor/plugins/placeholder";
import useEditor from "components/shared/inputs/Editor/useEditor";
import useMe from "hooks/useMe";

const AnswerInputStyled = styled(Editor)(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  cursor: "text",
  "& .ProseMirror": {
    flex: 1,
    minHeight: "40px",
    padding: "0 12px",
    borderTopLeftRadius: theme.spacing(0.5),
    borderBottomLeftRadius: theme.spacing(0.5),
    "& .placeholder": {
      ...theme.typography.body2,
    },
    "& p": {
      lineHeight: "1.25",
      margin: "13px 0",
    },
  },
  [theme.breakpoints.down("md")]: {
    flexBasis: "100%",
  },
}));

type Props = {
  inputRef: RefObject<Element>;
  maxChars?: number;
  onCancel: () => void;
  onSubmit: (input: { answer: string }) => Promise<void>;
};

export default function AnswerInput({
  inputRef,
  maxChars = 0,
  onCancel,
  onSubmit,
}: Props) {
  const editorRef: EditorViewRef = useRef();
  const editorAnchorRef = useRef();

  const me = useMe();
  const snackbarRef = useRef(null);
  const isSmUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const [state, setState] = useEditor({
    content: "",
    plugins: [
      autoLink(),
      editLink(),
      placeholder({
        text: "Add an answer",
      }),
    ],
  });

  const totalChars = state?.doc?.textContent.length || 0;

  const resetInput = () => {
    const tr = clearAllContent(state);
    setState(state.apply(tr));
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const answer = getContentAsMarkdown(state.doc);
    if (!answer) return;

    try {
      await onSubmit({ answer });
    } catch (e) {
      setIsSubmitting(false);
      snackbarRef?.current?.callSnackbar(
        "We were unable to submit your post. Please try again.",
        "error"
      );
    }
    setIsSubmitting(false);
    setConfirmationDialogOpen(false);
    resetInput();
  };

  const canSubmit = docHasContent(state.doc);
  const view = editorRef?.current?.view;

  return (
    <Box ref={inputRef} my={1}>
      <Box alignItems="flex-start" display="flex" mb={1}>
        {isSmUp && (
          <Box mt={0.5}>
            <Avatar alt={me?.name?.fullName} src={me?.profile?.avatar} />
          </Box>
        )}

        <Box
          ref={editorAnchorRef}
          ml={2}
          sx={(theme) => ({
            border: `1px solid ${theme.palette.text.secondary}`,
            borderRadius: "8px",
            flex: 1,
            minWidth: 0,
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-end",

            [theme.breakpoints.only("xs")]: {
              margin: 0,
            },
          })}
        >
          <AnswerInputStyled
            ref={editorRef}
            setState={setState}
            state={state}
          />

          <LinkForm
            anchorEl={editorAnchorRef.current}
            setState={setState}
            state={state}
            view={view}
          />

          <Box alignItems="center" display="flex" flexDirection="row">
            <InsertLinkButton setState={setState} state={state} view={view} />
          </Box>
        </Box>
      </Box>

      {!!maxChars && (
        <Box display="flex" justifyContent="flex-end">
          <Typography
            color={totalChars > maxChars ? "error" : "textPrimary"}
            variant="caption"
          >
            {totalChars}/{maxChars} characters
          </Typography>
        </Box>
      )}

      <Stack
        direction="row"
        justifyContent="flex-end"
        ml={1.25}
        mt={1}
        spacing={2}
      >
        <Button onClick={onCancel}>Cancel</Button>

        <LoadingButton
          disabled={
            !canSubmit || isSubmitting || (maxChars && totalChars > maxChars)
          }
          loading={isSubmitting}
          sx={{ textTransform: "capitalize" }}
          variant="outlined"
          onClick={() => setConfirmationDialogOpen(true)}
        >
          Submit
        </LoadingButton>
      </Stack>

      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
      >
        <DialogTitle>Ready to submit?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Once you click submit, your answer will go to an editor.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmationDialogOpen(false)}>
            Go back
          </Button>
          <LoadingButton
            className="pendo_web-groupsep-submit-confirm"
            loading={isSubmitting}
            variant="contained"
            onClick={handleSubmit}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
