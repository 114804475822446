import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext, useMemo } from "react";

import { GROUP_PINNED_FEED_GROUP } from "lib/motorcade/constants";
import { StreamContext } from "lib/motorcade/providers/stream";

export function usePinFeed(feedId: string) {
  const feedQueryKey = useMemo(() => {
    return `${GROUP_PINNED_FEED_GROUP}:${feedId}`;
  }, [feedId]);
  const { streamClient } = useContext(StreamContext);
  const feed = streamClient?.feed(GROUP_PINNED_FEED_GROUP, feedId);
  const queryClient = useQueryClient();

  const updatePinnedActivitiesCache = (updatedActivities) => {
    queryClient.setQueryData([feedQueryKey], () => {
      return updatedActivities;
    });
  };

  const { isLoading, data } = useQuery({
    enabled: !!streamClient,
    queryKey: [feedQueryKey],
    staleTime: Infinity,
    queryFn: async () => {
      const activities = await feed?.get();
      return activities?.results?.length > 0 ? activities.results : [];
    },
    refetchOnWindowFocus: false,
  });

  return {
    activities: data ?? [],
    updatePinnedActivitiesCache,
    isLoading,
  };
}

export default usePinFeed;
