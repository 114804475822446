import { useMutation as useApolloMutation } from "@apollo/client";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FeedAPIResponse } from "getstream";
import { useContext } from "react";

import { EventValidationSchemaType } from "components/feed/ActivityEditor/editors/EventEditorDialog/utils";
import useMe from "hooks/useMe";
import {
  FEED_EVENT_CREATE,
  FEED_EVENT_DELETE,
  FEED_EVENT_UPDATE,
  MEDIA_UPLOAD,
} from "lib/motorcade/graphql/mutations";
import { StreamContext } from "lib/motorcade/providers/stream";
import { AlertCallback, FeedActivity } from "lib/motorcade/types";
import {
  deleteActivity,
  prependFeedActivities,
  updateFeedActivity,
} from "lib/motorcade/utils/state";
import { getEventActivityStub } from "lib/motorcade/utils/stubs";
import { Group } from "types/group";

// @todo Generate types from graph schema instead of manually creating them
export type FeedEventCreateInput = {
  hostId?: string;
  host?: {
    userId: string;
    name: string;
    avatar: string;
  };
  attachment?: {
    url: string;
    mediaType: string;
  };
  startDate: string;
  timezone: string;
  groupId?: string;
  group?: Pick<Group, "id" | "name" | "type">;
  dataUri?: string | ArrayBuffer | null;
} & Omit<
  EventValidationSchemaType,
  "host" | "attachment" | "startDate" | "timezone"
>;

export type FeedEventUpdateInput = {
  imageURL?: string;
  eventId?: string;
} & FeedEventCreateInput;

export type FeedEventDeleteInput = {
  activityId: string;
  foreignId: string;
};

export function useEvent(
  activity?: FeedActivity,
  alertCallback: AlertCallback
) {
  const me = useMe();
  const { currentFeedQueryKey } = useContext(StreamContext);
  const queryClient = useQueryClient();

  const [feedMediaUpload] = useApolloMutation(MEDIA_UPLOAD);
  const [feedEventCreate] = useApolloMutation(FEED_EVENT_CREATE);
  const [feedEventUpdate] = useApolloMutation(FEED_EVENT_UPDATE);
  const [feedEventDelete, { loading: deleting }] = useApolloMutation(
    FEED_EVENT_DELETE,
    {
      update: (cache, { data }) => {
        const normalizedId = cache.identify({
          id: data?.feedEventDelete,
          __typename: "FeedEvent",
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
    }
  );

  const { mutateAsync: addEvent, isLoading: addEventLoading } = useMutation({
    mutationFn: async (input: FeedEventCreateInput) => {
      const { dataUri, host, group, ...inputData } = input;
      if (dataUri) {
        const {
          data: { mediaUpload },
        } = await feedMediaUpload({
          variables: { dataUri },
        });
        inputData.attachment = {} as FeedEventCreateInput["attachment"];
        inputData.attachment.url = mediaUpload;
        inputData.attachment.mediaType = "image";
      }
      if (host) {
        inputData.hostId = host.userId;
      }
      if (group) {
        inputData.groupId = group.id;
      }
      const newActivity = await feedEventCreate({
        variables: {
          input: inputData,
        },
      });

      return { newActivity };
    },
    onMutate: async (input: FeedEventCreateInput) => {
      await queryClient.cancelQueries({ queryKey: [currentFeedQueryKey] });
      const prevFeed = queryClient.getQueryData([currentFeedQueryKey]);

      const pendingActivity = getEventActivityStub({ author: me, input });

      queryClient.setQueryData(
        [currentFeedQueryKey],
        (prevData: FeedAPIResponse) => {
          return prependFeedActivities({
            feedData: prevData,
            activities: [pendingActivity],
          });
        }
      );

      return { prevFeed };
    },
    onSuccess: () => {
      alertCallback({
        message: "Event successfully created.",
        type: "success",
      });
    },
    onError: (_err, _variables, context) => {
      queryClient.setQueryData([currentFeedQueryKey], context.prevFeed);
      alertCallback({
        message: "Failed to create event. Try again.",
        type: "error",
      });
    },
  });

  const { mutate: updateEvent } = useMutation({
    mutationFn: async (input: FeedEventUpdateInput) => {
      const { dataUri, imageURL, host, ...inputData } = input;

      if (
        dataUri &&
        typeof dataUri === "string" &&
        dataUri.includes("data:image")
      ) {
        const {
          data: { mediaUpload },
        } = await feedMediaUpload({
          variables: { dataUri },
        });
        inputData.attachment = {} as FeedEventUpdateInput["attachment"];
        inputData.attachment.url = mediaUpload;
        inputData.attachment.mediaType = "image";
      } else if (imageURL && inputData?.attachment?.url !== imageURL) {
        inputData.attachment = {} as FeedEventUpdateInput["attachment"];
        inputData.attachment.url = imageURL;
        inputData.attachment.mediaType = "image";
      } else if (!inputData.attachment) {
        inputData.attachment = {
          url: "",
          mediaType: "",
        };
      }

      inputData.hostId = host?.userId ? host.userId : "";

      delete inputData.audience;

      const updatedActivity = await feedEventUpdate({
        variables: {
          input: inputData,
        },
      });

      return { updatedActivity };
    },
    onMutate: async (input: FeedEventUpdateInput) => {
      await queryClient.cancelQueries({ queryKey: [currentFeedQueryKey] });
      const prevFeed = queryClient.getQueryData([currentFeedQueryKey]);

      const mediaUrl =
        input?.attachment?.url ?? input?.dataUri ?? input?.imageURL ?? null;
      const mediaType = mediaUrl ? "image" : null;

      queryClient.setQueryData(
        [currentFeedQueryKey],
        (feed: FeedAPIResponse) => {
          return updateFeedActivity({
            feed,
            activity,
            newData: {
              host: input?.host?.userId
                ? {
                    id: input?.host?.userId,
                    data: {
                      name: input?.host?.name,
                      avatar: input?.host?.avatar,
                    },
                  }
                : null,
              mediaUrl,
              mediaType,
              object: {
                id: activity?.object?.id,
                data: {
                  ...input,
                  startDate: input.startDate.split("T")[0],
                },
                updated_at: new Date().toISOString(),
              },
            },
            queryKey: currentFeedQueryKey,
          });
        }
      );

      return { prevFeed };
    },
    onSuccess: () => {
      alertCallback({
        message: "Event successfully updated.",
        type: "success",
      });
    },
    onError: (_err, _variables, context) => {
      queryClient.setQueryData([currentFeedQueryKey], context.prevFeed);
      alertCallback({
        message: "Failed to update event. Try again.",
        type: "error",
      });
    },
  });

  const { mutate: deleteEvent } = useMutation({
    mutationFn: async (input: FeedEventDeleteInput) => {
      feedEventDelete({
        variables: {
          id: input.foreignId,
        },
      });
    },
    onMutate: async (input: FeedEventDeleteInput) => {
      await queryClient.cancelQueries({ queryKey: [currentFeedQueryKey] });
      const prevFeed = queryClient.getQueryData([currentFeedQueryKey]);

      queryClient.setQueryData(
        [currentFeedQueryKey],
        (feed: FeedAPIResponse) => {
          return deleteActivity({
            feedData: feed,
            activityId: input.activityId,
          });
        }
      );
      return { prevFeed };
    },
    onSuccess: () => {
      alertCallback({
        message: "Event successfully deleted.",
        type: "success",
      });
    },
    onError: (_err, _variables, context) => {
      queryClient.setQueryData([currentFeedQueryKey], context.prevFeed);
      alertCallback({
        message: "Failed to delete event. Try again.",
        type: "error",
      });
    },
  });

  return {
    addEvent,
    addEventLoading,
    updateEvent,
    deleteEvent,
    deleting,
  };
}
