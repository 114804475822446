import { Stack } from "@mui/system";
import { useEffect, useRef, useState } from "react";

import { useApp } from "lib/common/appProvider";
import useComment from "lib/motorcade/hooks/useComment";
import { AlertCallback } from "lib/motorcade/types";

import ActivityCardActions from "./ActivityCardActions";
import ActivityCardCommentInput from "./ActivityCardCommentInput";
import ActivityCardComments from "./ActivityCardComments";

export default function ActivityCardFooter({
  activity,
  customAction = null,
  hideComment = false,
}) {
  const snackbarRef = useApp("snackbarRef");
  const alertCallback: AlertCallback = ({ message, type, title }) => {
    snackbarRef?.current?.callSnackbar(message, type, title);
  };
  const commentInputRef = useRef(null);

  const [isCommentInputOpen, setIsCommentInputOpen] = useState(false);
  const { addComment, addCommentLoading } = useComment({
    activity,
    alertCallback,
  });

  useEffect(() => {
    if (isCommentInputOpen && commentInputRef.current) {
      setTimeout(() => {
        commentInputRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }, 0);
    }
  }, [isCommentInputOpen]);

  return (
    <Stack spacing={1} sx={{ pb: 2, pt: 0, px: 3 }}>
      <ActivityCardActions
        activity={activity}
        customAction={customAction}
        hideComment={hideComment}
        onCommentClick={() => setIsCommentInputOpen((prev) => !prev)}
      />

      {!hideComment && <ActivityCardComments activity={activity} />}

      <ActivityCardCommentInput
        ref={commentInputRef}
        isSubmitting={addCommentLoading}
        open={isCommentInputOpen}
        type="comment"
        onSubmit={async (input) => {
          await addComment(input);
        }}
      />
    </Stack>
  );
}
