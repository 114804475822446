import { Box, Skeleton } from "@mui/material";

export default function ActivityCardHeaderLoading() {
  return (
    <Box display="flex" px={3} py={2}>
      <Box>
        <Skeleton height={40} variant="circular" width={40} />
      </Box>

      <Box ml={1} width="100%">
        <Skeleton width="30%" />
        <Skeleton width="15%" />
      </Box>
    </Box>
  );
}
