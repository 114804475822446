import { gql } from "@apollo/client";

export const FEED_POLL_VOTE_CREATE = gql`
  mutation feedPollVoteCreate($input: FeedPollVoteCreateInput!) {
    feedPollVoteCreate(input: $input) {
      pollId
    }
  }
`;

export const FEED_POLL_VOTE_UPDATE = gql`
  mutation feedPollVoteUpdate($input: FeedPollVoteUpdateInput!) {
    feedPollVoteUpdate(input: $input) {
      pollId
    }
  }
`;

export const FEED_POLL_VOTE_DELETE = gql`
  mutation feedPollVoteDelete($input: FeedPollVoteDeleteInput!) {
    feedPollVoteDelete(input: $input)
  }
`;

export const FEED_POLL_CREATE = gql`
  mutation feedPollCreate($input: FeedPollCreateInput!) {
    feedPollCreate(input: $input) {
      id
    }
  }
`;

export const FEED_POLL_UPDATE = gql`
  mutation feedPollUpdate($input: FeedPollUpdateInput!) {
    feedPollUpdate(input: $input) {
      id
    }
  }
`;

export const FEED_POLL_DELETE = gql`
  mutation feedPollDelete($input: FeedPollDeleteInput!) {
    feedPollDelete(input: $input)
  }
`;
