import { Box } from "@mui/system";

export default function PollDeleted() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      mb={3}
      mx="auto"
      px={2}
      py={3}
      sx={{ backgroundColor: "grey.100" }}
      width="458px"
    >
      This poll has been removed
    </Box>
  );
}
