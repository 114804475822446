import { Box, Card, CardContent, Skeleton, Typography } from "@mui/material";

type Props = {
  withMedia?: boolean;
};

export default function FeedCardLoading({ withMedia }: Props) {
  return (
    <Card>
      <CardContent>
        <Box display="flex">
          <Box>
            <Skeleton height={40} variant="circular" width={40} />
          </Box>

          <Box ml={1} width="100%">
            <Skeleton width="30%" />
            <Skeleton width="15%" />
          </Box>
        </Box>

        {withMedia ? (
          <Box display="flex" mb={1.5} mt={3}>
            <Skeleton height={225} variant="rectangular" width={400} />

            <Box flexGrow={1} ml={3}>
              <Typography>
                <Skeleton width="100%" />
                <Skeleton width="100%" />
                <Skeleton width="100%" />
                <Skeleton width="100%" />
                <Skeleton width="100%" />
                <Skeleton width="100%" />
                <Skeleton width="100%" />
                <Skeleton width="100%" />
                <Skeleton width="45%" />
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box mb={1.5} mt={3}>
            <Typography>
              <Skeleton width="100%" />
              <Skeleton width="100%" />
              <Skeleton width="45%" />
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
}
