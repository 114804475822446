import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Image from "next/image";
import { ReactNode } from "react";

import Truncate from "components/shared/Truncate";
import externalImageLoader from "lib/common/externalImageLoader";
import { UrlMetadata } from "lib/motorcade/types";

import MediaContentLoading from "./MediaContentLoading";

type Props = {
  cta?: ReactNode;
  imageAlignment?: "left" | "right";
  label?: ReactNode;
  loading?: boolean;
  urlMetadata: UrlMetadata;
};

export default function MediaContent({
  cta = null,
  imageAlignment = "right",
  label,
  loading = false,
  urlMetadata,
}: Props) {
  return loading ? (
    <MediaContentLoading imageAlignment={imageAlignment} />
  ) : (
    <Grid
      container
      px={3}
      py={2}
      spacing={2}
      sx={{
        flexDirection: {
          xs: "column-reverse",
          sm: imageAlignment === "left" ? "row-reverse" : "row",
        },
      }}
    >
      <Grid
        item
        sm={urlMetadata?.image ? 6 : 12}
        sx={{
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
        }}
        xs={12}
      >
        {label && <Box pb={1}>{label}</Box>}

        {urlMetadata?.title && (
          <Box pb={1}>
            <Truncate expandable={false} maxLines={3}>
              <Typography
                color="textPrimary"
                component="a"
                href={urlMetadata?.url}
                rel="noreferrer"
                target="_blank"
                variant="h3"
              >
                {urlMetadata?.title}
              </Typography>
            </Truncate>
          </Box>
        )}

        {urlMetadata?.description && (
          <Box>
            <Truncate expandable={false} maxLines={3}>
              <Typography variant="body2">
                {urlMetadata?.description}
              </Typography>
            </Truncate>
          </Box>
        )}

        {cta && <Box pt={2}>{cta}</Box>}
      </Grid>

      {urlMetadata?.image && (
        <Grid
          item
          sm={6}
          sx={{
            minHeight: {
              xs: 162,
              sm: 228,
            },
          }}
          xs={12}
        >
          <Box
            height="100%"
            position="relative"
            sx={{
              minHeight: {
                xs: 162,
                sm: 228,
              },
            }}
            width="100%"
          >
            <a href={urlMetadata.url} rel="noreferrer" target="_blank">
              <Image
                fill
                alt=""
                loader={(loaderProps) =>
                  externalImageLoader({
                    src: loaderProps.src,
                    width: 450,
                    height: 350,
                    srcSetWidth: loaderProps.width,
                  })
                }
                src={urlMetadata.image}
                style={{
                  borderRadius: "4px",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </a>
          </Box>
        </Grid>
      )}
    </Grid>
  );
}
