import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

type Props = {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
};

export default function DeletePollDialog({ open, onClose, onDelete }: Props) {
  return (
    <Dialog maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle>Delete poll?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete your poll?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Go back</Button>
        <LoadingButton variant="contained" onClick={onDelete}>
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
