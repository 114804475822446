import { Dialog, useMediaQuery } from "@mui/material";
import { Theme } from "@mui/system";
import { useState } from "react";

import {
  Audience as AudienceType,
  FeedActivity,
  PollCollection,
} from "lib/motorcade/types";
import { Group } from "types/group";

import DiscardDialog from "./DiscardDialog";
import EventEditorDialog from "./editors/EventEditorDialog";
import PollEditorDialog, {
  Schema as PollSchema,
} from "./editors/PollEditorDialog";
import PostEditorDialog from "./editors/PostEditorDialog";

type Props = {
  open: boolean;
  onClose: () => void;
  activity?: FeedActivity;
  initialData?: {
    audience: AudienceType;
    group: Pick<Group, "id" | "name" | "type">;
  };
  initialEditor?: "post" | "poll" | "event";
};

export type ActivityEditorData = {
  audience: AudienceType;
  poll?: PollCollection & { form?: { duration?: PollSchema["duration"] } };
  group?: Pick<Group, "id" | "name" | "type">;
};

const DEFAULT_EDITOR = "post";
const DEFAULT_AUDIENCE: AudienceType = "general";

const DEFAULT_ACTIVITY_EDITOR_DATA = {
  audience: DEFAULT_AUDIENCE,
};

export default function ActivityEditorDialog({
  open,
  onClose,
  activity,
  initialData,
  initialEditor,
}: Props) {
  const initialActivityEditorData = {
    ...DEFAULT_ACTIVITY_EDITOR_DATA,
    ...initialData,
    ...(activity?.audience && activity?.audience !== "general"
      ? {
          audience: activity?.audience,
          group: activity?.groupRef && {
            id: activity?.groupRef?.id,
            name: activity?.groupRef?.data?.name,
            type: activity?.groupRef?.data?.type,
          },
        }
      : {}),
    poll: activity?.verb === "poll" && (activity?.object as PollCollection),
  };

  const isSmDown = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const [isDirty, setIsDirty] = useState(false);
  const [discardDialogOpen, setDiscardDialogOpen] = useState(false);
  const [activeEditorDialog, setActiveEditorDialog] = useState(
    initialEditor ?? DEFAULT_EDITOR
  );
  const [activityEditorData, setActivityEditorData] =
    useState<ActivityEditorData>(initialActivityEditorData);

  const handleCloseAndReset = () => {
    onClose();
    setIsDirty(false);
    setActiveEditorDialog(DEFAULT_EDITOR);
    setActivityEditorData(initialActivityEditorData);
  };

  const handleClose = () =>
    isDirty ? setDiscardDialogOpen(true) : handleCloseAndReset();

  const handleDiscardCancel = () => setDiscardDialogOpen(false);
  const handleDiscardConfirm = () => {
    setDiscardDialogOpen(false);
    handleCloseAndReset();
  };

  return (
    <>
      <DiscardDialog
        isEditing={!!activity}
        open={discardDialogOpen}
        onCancel={handleDiscardCancel}
        onDiscard={handleDiscardConfirm}
      />

      <Dialog
        fullWidth
        fullScreen={isSmDown}
        maxWidth="sm"
        open={open}
        onClose={handleClose}
      >
        {open && (
          <>
            {activeEditorDialog === "post" && (
              <PostEditorDialog
                activity={activity}
                activityEditorData={activityEditorData}
                canSelectAudience={!initialData?.audience}
                setActiveEditorDialog={setActiveEditorDialog}
                setActivityEditorData={setActivityEditorData}
                setIsDirty={setIsDirty}
                onClose={handleClose}
                onSubmit={handleCloseAndReset}
              />
            )}

            {activeEditorDialog === "poll" && (
              <PollEditorDialog
                activityEditorData={activityEditorData}
                setActivityEditorData={setActivityEditorData}
                setIsDirty={setIsDirty}
                onClose={handleClose}
                onConfirm={() => setActiveEditorDialog("post")}
              />
            )}

            {activeEditorDialog === "event" && (
              <EventEditorDialog
                activity={
                  activity?.verb === "event"
                    ? { ...activity, verb: "event" }
                    : undefined
                }
                activityEditorData={activityEditorData}
                canSelectAudience={!initialData?.audience && !activity}
                setActivityEditorData={setActivityEditorData}
                setIsDirty={setIsDirty}
                onClose={handleClose}
                onReset={handleCloseAndReset}
              />
            )}
          </>
        )}
      </Dialog>
    </>
  );
}
