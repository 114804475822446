import { Backdrop, Box } from "@mui/material";
import { useState } from "react";

import { FeedActivity } from "lib/motorcade/types";

type Props = {
  activity: FeedActivity;
  isPinned: boolean;
};

export default function MediaDisplay({ activity, isPinned }: Props) {
  const [isFull, setIsFull] = useState(false);

  if (!activity) return null;

  const { mediaType, mediaUrl } = activity;

  if (!mediaType || !mediaUrl) return null;

  return (
    <>
      {mediaType.includes("image") && (
        <Box
          sx={
            isPinned && {
              width: "100%",
              overflow: "hidden",
              "& img": {
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "center",
              },
            }
          }
        >
          <Box width="fit-content" onClick={() => setIsFull(true)}>
            <Box
              alt={mediaUrl}
              component="img"
              src={mediaUrl}
              sx={(theme) =>
                !isPinned && {
                  width: "100%",
                  height: "auto",
                  objectFit: "cover",
                  objectPosition: "center",
                  borderRadius: "5px",
                  maxHeight: "350px",
                  maxWidth: "350px",
                  marginBottom: "10px",
                  cursor: "pointer",
                  [theme.breakpoints.up("sm")]: {
                    width: "auto",
                    height: "auto",
                  },
                }
              }
            />
          </Box>
        </Box>
      )}
      {mediaType.includes("video") && (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video controls height="300">
          <source src={mediaUrl} />
        </video>
      )}
      <Backdrop
        open={isFull}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
        onClick={() => setIsFull(false)}
      >
        <Box
          height="100vh"
          px={{ xs: 2, md: 12 }}
          py={{ xs: 2, md: 4 }}
          width="100vw"
        >
          <Box
            alt={mediaUrl}
            component="img"
            src={mediaUrl}
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </Box>
      </Backdrop>
    </>
  );
}
