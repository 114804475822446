import { Box, Button, Divider, Typography } from "@mui/material";
import Card from "@mui/material/Card";

import ActivityCardFooter from "components/feed/FeedCard/base/ActivityCardFooter";
import MediaContent from "components/feed/FeedCard/cards/MediaCard/MediaContent";
import ExpertPanelQuote from "components/icons/ExpertPanelQuote";
import { FeedActivity, UrlMetadata } from "lib/motorcade/types";

import ActivityCardHeader from "../base/ActivityCardHeader";

type Props = {
  activity: FeedActivity;
  isPinned?: boolean;
  loading: boolean;
  urlMetadata: UrlMetadata;
};

export default function GroupEPRoundupCard({
  activity,
  isPinned,
  loading,
  urlMetadata,
}: Props) {
  let pendoClass = "pendo_web-ep-roundup-group";

  if (isPinned) pendoClass = "pendo_groups-pinned-ep";

  return (
    <Card className={pendoClass}>
      <ActivityCardHeader
        actionText="published an Expert Panel"
        activity={activity}
        actors={[activity.groupRef]}
        date={activity?.object?.updated_at}
      />
      <Box px={3}>
        <Divider />
      </Box>
      <MediaContent
        cta={
          urlMetadata?.provider && (
            <Button
              className="pendo_web-feed-card-read-article"
              href={urlMetadata?.url}
              target="_blank"
              variant="contained"
            >
              Read article
            </Button>
          )
        }
        imageAlignment="left"
        label={
          <Box display="flex" gap={1}>
            <ExpertPanelQuote sx={{ fontSize: "24px" }} />
            <Typography variant="body1Semibold">Expert Panel</Typography>
          </Box>
        }
        loading={loading}
        urlMetadata={urlMetadata}
      />
      <ActivityCardFooter activity={activity} />
    </Card>
  );
}
