import { format, isThisYear, isToday } from "date-fns";

export function formatActivityTime(date: string) {
  const timezoneFixedDate = date?.includes("Z") ? date : `${date}Z`;
  const dateObj = new Date(timezoneFixedDate);
  if (isToday(dateObj)) {
    return format(dateObj, "h:mm a");
  }
  if (isThisYear(dateObj)) {
    return format(dateObj, "MMMM d 'at' h:mm a");
  }
  return format(dateObj, "MMMM d y 'at' h:mm a");
}
