import { EnrichedActivity } from "getstream";

import { StreamUser } from "lib/motorcade/types";

import CommunityEPAnswerCard from "./cards/CommunityEPAnswerCard";
import EventCard from "./cards/EventCard";
import FollowCard from "./cards/FollowCard";
import GroupEPQuestionCard from "./cards/GroupEpQuestionCard";
import JoinCard from "./cards/JoinCard";
import MediaCard from "./cards/MediaCard";
import PollCard from "./cards/PollCard";
import PostCard from "./cards/PostCard";
import ThirdPartyCard from "./cards/ThirdPartyCard";

type Props = {
  activity: EnrichedActivity;
  isPinned?: boolean;
};

const cardsDictionary = {
  article: MediaCard,
  event: EventCard,
  community_expertpanel_answer: CommunityEPAnswerCard,
  expertpanel: MediaCard,
  follow: FollowCard,
  group_expertpanel: GroupEPQuestionCard,
  join: JoinCard,
  poll: PollCard,
  post: PostCard,
  thirdparty: ThirdPartyCard,
};

export default function FeedCard({ activity, isPinned = false }: Props) {
  if (!activity) return null;

  const actors = activity?.activities
    ? Object.values(
        activity.activities.reduce<Record<string, StreamUser>>(
          (acc, currentActivity) => {
            const { actor } = currentActivity;

            if (actor && actor?.collection !== "group" && !acc[actor.id]) {
              acc[actor.id] = actor;
            }
            return acc;
          },
          {}
        )
      )
    : [activity.actor];

  const parsedActivity = activity?.activities?.[0] ?? activity;
  parsedActivity.parentId = activity.id;

  const { verb } = activity;

  const Component = cardsDictionary[verb];

  if (!Component) return null;

  return (
    <Component activity={parsedActivity} actors={actors} isPinned={isPinned} />
  );
}
