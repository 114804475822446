import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { useApp } from "lib/common/appProvider";

import { READABLE_ACTIVITY_VERB } from "lib/motorcade/constants";

import useActivity from "lib/motorcade/hooks/useActivity";
import { useEvent } from "lib/motorcade/hooks/useEvent";
import { AlertCallback, FeedActivity } from "lib/motorcade/types";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  activity: FeedActivity;
};

export default function DeleteActivityDialog({
  isOpen,
  handleClose,
  activity,
}: Props) {
  const snackbarRef = useApp("snackbarRef");
  const alertCallback: AlertCallback = ({ message, type, title }) => {
    snackbarRef?.current?.callSnackbar(message, type, title);
  };

  const { deleteActivity } = useActivity({
    activity,
    alertCallback,
  });
  const { deleteEvent, deleting } = useEvent(activity, alertCallback);

  const handleDeletePost = () => {
    deleteActivity();
    handleClose();
  };

  const handleDeleteEvent = () => {
    deleteEvent({
      foreignId: activity?.foreign_id,
      activityId: activity?.id,
    });
    handleClose();
  };

  return (
    <Dialog maxWidth="sm" open={isOpen} onClose={handleClose}>
      <DialogTitle>{`Delete ${
        READABLE_ACTIVITY_VERB[activity?.verb]
      }`}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`Are you sure you want to permanently delete this ${
            READABLE_ACTIVITY_VERB[activity?.verb]
          }? This action
          cannot be undone.`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton
          autoFocus
          color="error"
          loading={deleting}
          variant="contained"
          onClick={
            activity?.verb === "event" ? handleDeleteEvent : handleDeletePost
          }
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
