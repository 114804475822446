import { useMutation as useApolloMutation } from "@apollo/client";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FeedAPIResponse } from "getstream";
import { useContext } from "react";

import useMe from "hooks/useMe";
import { useApp } from "lib/common/appProvider";
import {
  FEED_POST_CREATE,
  FEED_POST_UPDATE,
} from "lib/motorcade/graphql/mutations";
import { StreamContext } from "lib/motorcade/providers/stream";
import {
  AlertCallback,
  Audience as AudienceType,
  FeedActivity,
} from "lib/motorcade/types";
import {
  prependFeedActivities,
  updateFeedActivity,
} from "lib/motorcade/utils/state";
import { getPostActivityStub } from "lib/motorcade/utils/stubs";
import { Group } from "types/group";

// @todo Generate types from graph schema instead of manually creating them
export type FeedPostCreateInput = {
  markdown: string;
  audience: AudienceType;
  attachment?: {
    url: string;
    mediaType: string;
  };
  mentionedUserIds?: string[];
  group?: Pick<Group, "id" | "name" | "type">;
};

export type FeedPostUpdateInput = {
  markdown: string;
  attachment?: {
    url: string;
    mediaType: string;
  };
  mentionedUserIds?: string[];
};

type UsePostProps = {
  activity?: FeedActivity;
  alertCallback: AlertCallback;
};

export function usePost({ activity, alertCallback }: UsePostProps) {
  const site = useApp("site");
  const conciergeEmail = site?.concierge?.email;
  const me = useMe();
  const { currentFeedQueryKey } = useContext(StreamContext);
  const queryClient = useQueryClient();

  const [feedPostCreate] = useApolloMutation(FEED_POST_CREATE);
  const [feedPostUpdate] = useApolloMutation(FEED_POST_UPDATE);

  const { mutateAsync: addPost, isLoading: addPostLoading } = useMutation({
    mutationFn: async (input: FeedPostCreateInput) => {
      const newActivity = await feedPostCreate({
        variables: {
          input: {
            markdown: input.markdown,
            audience: input.audience.toUpperCase(),
            attachment: input?.attachment,
            mentionedUserIds: input.mentionedUserIds,
            groupId: input?.group?.id,
          },
        },
      });

      return { newActivity };
    },
    onMutate: async (input: FeedPostCreateInput) => {
      await queryClient.cancelQueries({ queryKey: [currentFeedQueryKey] });
      const prevFeed = queryClient.getQueryData([currentFeedQueryKey]);

      const pendingActivity = getPostActivityStub({ author: me, input });

      queryClient.setQueryData(
        [currentFeedQueryKey],
        (prevData: FeedAPIResponse) => {
          return prependFeedActivities({
            feedData: prevData,
            activities: [pendingActivity],
          });
        }
      );

      return { prevFeed };
    },
    onSuccess: () => {
      alertCallback({
        message: "Post successfully created.",
        type: "success",
      });
    },
    onError: (err, _variables, context) => {
      queryClient.setQueryData([currentFeedQueryKey], context.prevFeed);

      if (
        err instanceof Error &&
        err.message === "Request denied. Action is not permitted for User."
      ) {
        alertCallback({
          message: `We have received several reports of spam posts associated with your account, leading to a temporary access restriction. Please reach out to ${conciergeEmail} so our team can reshare our community guidelines and assist you with restoring access.`,
          type: "error",
        });
      } else {
        alertCallback({
          message: "Failed to create post. Try again.",
          type: "error",
        });
      }
    },
  });

  const updatePost = useMutation({
    mutationFn: async (input: FeedPostUpdateInput) => {
      const updatedActivity = await feedPostUpdate({
        variables: {
          input: {
            feedPostId: activity?.foreign_id,
            markdown: input.markdown,
            attachment: input?.attachment,
            mentionedUserIds: input.mentionedUserIds,
          },
        },
      });

      return { updatedActivity };
    },
    onMutate: async (input: FeedPostUpdateInput) => {
      await queryClient.cancelQueries({ queryKey: [currentFeedQueryKey] });
      const prevFeed = queryClient.getQueryData([currentFeedQueryKey]);

      queryClient.setQueryData(
        [currentFeedQueryKey],
        (feed: FeedAPIResponse) => {
          return updateFeedActivity({
            feed,
            activity,
            newData: {
              mediaUrl: input?.attachment?.url,
              mediaType: input?.attachment?.mediaType,
              object: {
                data: { body: input.markdown },
                updated_at: new Date().toISOString(),
              },
            },
            queryKey: currentFeedQueryKey,
          });
        }
      );

      return { prevFeed };
    },
    onSuccess: () => {
      alertCallback({
        message: "Post successfully updated.",
        type: "success",
      });
    },
    onError: (_err, _variables, context) => {
      queryClient.setQueryData([currentFeedQueryKey], context.prevFeed);
      alertCallback({
        message: "Failed to update post. Try again.",
        type: "error",
      });
    },
  });

  return {
    addPost,
    addPostLoading,
    updatePost,
  };
}
