import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import { FeedActivity } from "lib/motorcade/types";

import ActivityCardFooter from "../base/ActivityCardFooter";

type Props = {
  activity: FeedActivity & {
    verb: "thirdparty";
  };
};

export default function ThirdPartyCard({ activity }: Props) {
  return (
    <Card>
      Third Party News Story Post
      <CardContent>
        <Typography gutterBottom color="text.secondary" sx={{ fontSize: 14 }}>
          {activity?.object?.data?.body}
        </Typography>
      </CardContent>
      <ActivityCardFooter activity={activity} />
    </Card>
  );
}
