import {
  Grid,
  Skeleton,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";

type Props = {
  imageAlignment?: "left" | "right";
};

export default function MediaContentLoading({
  imageAlignment = "right",
}: Props) {
  const isSmDown = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  return (
    <Grid
      container
      px={3}
      py={2}
      spacing={2}
      sx={{
        flexDirection: {
          xs: "column-reverse",
          sm: imageAlignment === "left" ? "row-reverse" : "row",
        },
      }}
    >
      <Grid
        item
        sm={6}
        sx={{
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
        }}
        xs={12}
      >
        <Typography sx={{ pb: 1, width: "150px" }} variant="body1Semibold">
          <Skeleton />
        </Typography>

        <Typography sx={{ pb: 1, width: "100%" }} variant="h3">
          <Skeleton />
          <Skeleton />
        </Typography>

        <Typography sx={{ pb: 2, width: "100%" }} variant="body2">
          <Skeleton />
          <Skeleton />
        </Typography>

        <Skeleton height={36} variant="rounded" width={isSmDown ? 150 : 319} />
      </Grid>

      <Grid
        item
        sm={6}
        sx={{
          minHeight: {
            xs: 162,
            sm: 228,
          },
        }}
        xs={12}
      >
        <Skeleton
          height="100%"
          sx={{
            minHeight: {
              xs: 162,
              sm: 228,
            },
          }}
          variant="rounded"
        />
      </Grid>
    </Grid>
  );
}
