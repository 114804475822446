import { useMutation } from "@apollo/client";
import { EnrichedActivity } from "getstream";

import { useApp } from "lib/common/appProvider";
import { STREAM_FLAG_ACTIVITY } from "lib/motorcade/graphql/mutations";

import ReportDialog from "./ReportDialog";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  activity: EnrichedActivity;
};

type ReportInput = {
  reason: string;
  streamActivityId: string;
  streamFeedId?: string;
  streamFeedGroup?: "user" | "group";
};

export default function ReportActivityDialog({
  isOpen,
  onClose,
  activity,
}: Props) {
  const snackbarRef = useApp("snackbarRef");

  const [reportActivity, { loading }] = useMutation(STREAM_FLAG_ACTIVITY);

  const onSubmit = async (reason: string) => {
    const input: ReportInput = {
      streamActivityId: activity?.id,
      reason,
      streamFeedGroup: activity?.postedTo?.group?.id ? "group" : "user",
      streamFeedId: activity?.postedTo?.group?.id ?? activity?.actor?.id,
    };

    try {
      await reportActivity({
        variables: { input },
      });
      snackbarRef?.current?.callSnackbar(
        "Report successfully submitted",
        "success"
      );
      onClose();
    } catch (error) {
      snackbarRef?.current?.callSnackbar(error.message, "error");
      onClose();
    }
  };

  return (
    <ReportDialog
      isLoading={loading}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
}
