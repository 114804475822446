import { useMutation as useApolloMutation } from "@apollo/client";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FeedAPIResponse } from "getstream";
import { useContext } from "react";

import { GROUP_EP_ANSWER_MUTATION } from "graphql/groups/mutations";
import useMe from "hooks/useMe";
import { StreamContext } from "lib/motorcade/providers/stream";
import { AlertCallback, FeedActivity } from "lib/motorcade/types";
import { updateFeedActivity } from "lib/motorcade/utils/state";
import { stubAnswer } from "lib/motorcade/utils/stubs";

// 25 is the maximum number of reactions that can be fetched from Stream
const ANSWER_PAGE_SIZE = 25;

export default function useGroupEp(
  activity: FeedActivity,
  alertCallback: AlertCallback,
  queryKey: string
) {
  const me = useMe();

  const { currentFeedQueryKey, streamClient } = useContext(StreamContext);
  const queryClient = useQueryClient();
  const [answerQuestion] = useApolloMutation(GROUP_EP_ANSWER_MUTATION);
  const localQueryKey = queryKey ?? currentFeedQueryKey;

  const { reaction_counts, latest_reactions, object } = activity;
  const answerCount = reaction_counts?.answer;
  const hasMoreAnswers = answerCount !== latest_reactions?.answer?.length;

  const { mutate: addAnswer } = useMutation({
    mutationKey: [localQueryKey],
    mutationFn: async ({ answer }: { answer: string }) => {
      await answerQuestion({
        variables: {
          input: {
            questionId: object?.data?.questionId,
            answer,
          },
        },
      });
    },
    onMutate: async (input: { answer: string }) => {
      await queryClient.cancelQueries({ queryKey: [localQueryKey] });
      const prevFeed = queryClient.getQueryData([localQueryKey]);

      const pendingAnswer = stubAnswer({ author: me, input });

      const newData = {
        ...activity,
        latest_reactions: {
          ...activity.latest_reactions,
          answer: [...(activity.latest_reactions.answer ?? []), pendingAnswer],
        },
        own_reactions: {
          ...activity.own_reactions,
          answer: [pendingAnswer],
        },
        reaction_counts: {
          ...activity?.reaction_counts,
          answer: (activity?.reaction_counts?.answer || 0) + 1,
        },
      };

      // Update activity in feed
      queryClient.setQueryData([localQueryKey], (feed: FeedAPIResponse) => {
        return updateFeedActivity({
          activity,
          feed,
          newData,
          queryKey: localQueryKey,
        });
      });

      return { prevFeed };
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData([localQueryKey], context.prevFeed);
      alertCallback({
        message:
          "An error occurred while submitting your answer, please try again",
        type: "error",
      });
    },
  });

  const { isLoading: loadingMoreAnswers, mutate: loadMoreAnswers } =
    useMutation({
      mutationFn: async () => {
        if (
          !latest_reactions?.answer ||
          latest_reactions?.answer?.length === 0
        ) {
          return [];
        }

        const lastAnswerId =
          latest_reactions.answer[latest_reactions.answer.length - 1].id;

        const { results } = await streamClient.reactions.filter({
          activity_id: activity.id,
          kind: "answer",
          id_lt: lastAnswerId,
          limit: ANSWER_PAGE_SIZE,
        });

        return results;
      },
      onSuccess: async (data) => {
        await queryClient.cancelQueries({
          queryKey: [localQueryKey],
        });

        const newData = {
          ...activity,
          latest_reactions: {
            ...activity.latest_reactions,
            answer: [...data, ...activity.latest_reactions.answer],
          },
        };

        queryClient.setQueryData(
          [localQueryKey],
          (prevData: FeedAPIResponse) => {
            // Update activity in feed
            return updateFeedActivity({
              activity,
              feed: prevData,
              newData,
              queryKey: localQueryKey,
            });
          }
        );
      },
      onError: () => {
        alertCallback({
          message: "An error occurred while fetching the Expert Panel answers",
          type: "error",
        });
      },
    });

  return {
    hasMoreAnswers,
    loadingMoreAnswers,
    loadMoreAnswers,
    addAnswer,
  };
}
