import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { format } from "date-fns";

import ProfilePopoverWrapper from "components/profile/ProfilePopoverWrapper";
import { FeedActivity, UrlMetadata } from "lib/motorcade/types";

import EditorsChoiceCardLoading from "./EditorsChoiceCardLoading";
import ActivityCardFooter from "../../base/ActivityCardFooter";
import MediaContentImage from "../MediaCard/MediaContentImage";

type Props = {
  activity: FeedActivity & {
    verb: "article";
  };
  loading: boolean;
  urlMetadata: UrlMetadata;
};

export default function EditorsChoiceCard({
  activity,
  loading,
  urlMetadata,
}: Props) {
  const author = activity?.actor;
  const createdAt = activity?.object?.created_at;
  const isSmUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const publishedUrl = activity?.object?.data?.url;
  if (loading) {
    return <EditorsChoiceCardLoading />;
  }

  return (
    <Card className="pendo_web-article-editorschoice">
      <CardContent sx={{ px: 3 }}>
        <Box
          sx={{
            display: { xs: "none", sm: "block" },
            padding: "8px 14px",
            background: "rgba(252,189,42,0.75)",
            width: "fit-content",
            mb: 2,
          }}
        >
          <Typography variant="button">Editor&apos;s Choice</Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            ...(isSmUp
              ? {
                  flexDirection: "row",
                  width: "calc(100% + 12px)",
                  margin: "0 -6px",
                  "& > *": {
                    margin: "0 6px",
                  },
                }
              : {}),
          }}
          width="100%"
        >
          {urlMetadata?.image && (
            <Box
              sx={{
                width: "100%",
                height: "184px",
                marginBottom: "16px",
                overflow: "hidden",
                position: "relative",
                objectFit: "cover",
                objectPosition: "center",
                borderRadius: "6px",

                ...(isSmUp
                  ? {
                      height: "184px",
                      width: "40%",
                      minWidth: "40%",
                      marginBottom: "0",
                    }
                  : {}),
              }}
            >
              <MediaContentImage
                data={urlMetadata}
                publishedUrl={publishedUrl}
              />
            </Box>
          )}

          <Box
            sx={{
              minWidth: "calc(51% - 10px)",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {urlMetadata?.title && (
              <Box mb="8px">
                <a href={publishedUrl} rel="noreferrer" target="_blank">
                  <Typography
                    className="truncate-2"
                    color="textPrimary"
                    variant="h3"
                  >
                    {urlMetadata?.title}
                  </Typography>
                </a>
              </Box>
            )}
            {urlMetadata?.description && (
              <Box
                mb="8px"
                py="4px"
                sx={{
                  marginBottom: isSmUp ? "16px" : "0",
                }}
              >
                <Typography
                  className="truncate-3"
                  component="div"
                  variant="body2"
                >
                  {urlMetadata?.description}
                </Typography>
              </Box>
            )}

            {author && (
              <Box alignItems="center" display="flex" mb="16px">
                <Box mr="12px">
                  {ProfilePopoverWrapper(
                    <Avatar alt={author?.data?.name} src={author?.data?.avatar}>
                      {author?.data?.name?.[0]}
                    </Avatar>,
                    author?.id
                  )}
                </Box>
                <Typography variant="body2">
                  by{" "}
                  {ProfilePopoverWrapper(
                    <Typography component="span" variant="body2Semibold">
                      {author?.data?.name}
                    </Typography>,
                    author?.id,
                    {
                      as: "span",
                    }
                  )}
                  , {format(new Date(createdAt), "MMM d")}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        {urlMetadata?.provider && (
          <Box mt={2}>
            <Button
              className="pendo_web-feed-card-read-article"
              href={publishedUrl}
              target="_blank"
              variant="contained"
            >
              Read article
            </Button>
          </Box>
        )}
      </CardContent>
      <ActivityCardFooter activity={activity} />
    </Card>
  );
}
