import { useMutation as useApolloMutation } from "@apollo/client";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { EnrichedActivity } from "getstream";
import { useContext } from "react";

import { FEED_POST_DELETE } from "lib/motorcade/graphql/mutations";
import { StreamContext } from "lib/motorcade/providers/stream";
import { AlertCallback } from "lib/motorcade/types";
import { updateFeedActivity } from "lib/motorcade/utils/state";

type Props = {
  activity: EnrichedActivity;
  alertCallback: AlertCallback;
};

export default function useActivity({ activity, alertCallback }: Props) {
  const queryClient = useQueryClient();
  const { currentFeedQueryKey, streamClient } = useContext(StreamContext);

  const [deletePost, { loading: deletingPost }] =
    useApolloMutation(FEED_POST_DELETE);

  const { mutate: deleteActivity, isLoading: isDeleting } = useMutation({
    mutationKey: [currentFeedQueryKey],
    mutationFn: async () => {
      await deletePost({ variables: { id: activity?.foreign_id } });
    },
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: [currentFeedQueryKey] });
      const previousState = queryClient.getQueryData([currentFeedQueryKey]);
      queryClient.setQueryData([currentFeedQueryKey], (oldData) => {
        if (oldData?.pages) {
          const updatedData = oldData?.pages?.map((page) => {
            return {
              ...page,
              results: page?.results?.filter(
                (cachedActivity) => cachedActivity?.id !== activity?.id
              ),
            };
          });
          return {
            ...oldData,
            pages: updatedData,
          };
        }
        return null;
      });
      return { previousState };
    },
    onSuccess: () => {
      alertCallback({
        message: "Post successfully deleted.",
        type: "success",
      });
    },
    onError: (_err, _variables, context) => {
      queryClient.setQueryData([currentFeedQueryKey], context.previousState);
      alertCallback({
        message: "Failed to delete post. Try again.",
        type: "error",
      });
    },
  });

  const { isLoading: loadingMoreComments, mutate: loadMoreComments } =
    useMutation({
      mutationFn: async () => {
        await queryClient.cancelQueries({ queryKey: [currentFeedQueryKey] });
        const lastCommentId = activity.latest_reactions.comment[0].id;

        const numberOfUnloadedComments =
          activity.reaction_counts.comment -
          activity.latest_reactions.comment.length;
        const numberOfCommentsToLoad = Math.min(numberOfUnloadedComments, 10);

        const { results } = await streamClient.reactions.filter({
          activity_id: activity.id,
          kind: "comment",
          id_lt: lastCommentId,
          limit: numberOfCommentsToLoad,
        });

        results.forEach((comment) => {
          if (comment.latest_children.reply) {
            comment.latest_children.reply = [];
          }
        });

        const newData = {
          ...activity,
          latest_reactions: {
            ...activity.latest_reactions,
            comment: [...results, ...activity.latest_reactions.comment],
          },
        };

        queryClient.setQueryData([currentFeedQueryKey], (feed) => {
          return updateFeedActivity({
            activity,
            feed,
            newData,
            queryKey: currentFeedQueryKey,
          });
        });
      },

      onError: (err, variables, context) => {
        console.error(err);
      },
    });

  return {
    deleteActivity,
    isDeleting: isDeleting || deletingPost,
    loadingMoreComments,
    loadMoreComments,
  };
}
