import { InsertChartOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import cls from "lib/common/cls";

type Props = {
  className?: string;
  disabled: boolean;
  onClick: () => void;
};

export default function CreatePollButton({
  className,
  disabled,
  onClick,
}: Props) {
  return (
    <IconButton
      className={cls([className])}
      disabled={disabled}
      size="large"
      title="Add Poll"
      onClick={onClick}
    >
      <InsertChartOutlined fontSize="small" />
    </IconButton>
  );
}
