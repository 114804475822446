import { OpenInNew } from "@mui/icons-material";
import { Button, Card, Divider, Theme, Typography } from "@mui/material";
import { Box, useMediaQuery } from "@mui/system";
import Link from "next/link";

import ActivityCardFooter from "components/feed/FeedCard/base/ActivityCardFooter";
import ActivityCardHeader from "components/feed/FeedCard/base/ActivityCardHeader";
import MediaContent from "components/feed/FeedCard/cards/MediaCard/MediaContent";
import EditSquare from "components/icons/EditSquare";
import useMe from "hooks/useMe";
import wasOrWere from "lib/feed/wasOrWere";
import { FeedActivity, StreamUser, UrlMetadata } from "lib/motorcade/types";

type Props = {
  activity: FeedActivity;
  actors?: StreamUser[];
  loading: boolean;
  urlMetadata: UrlMetadata;
};

export default function CommunityEPRoundupCard({
  activity,
  actors,
  loading,
  urlMetadata,
}: Props) {
  const { id: meId } = useMe();

  const epActionText = `${wasOrWere({ actors, meId })} featured in an Expert Panel`;

  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.only("xs"));
  const isSmDown = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  return (
    <Card className="pendo_web-ep-roundup-standard">
      <ActivityCardHeader
        actionText={epActionText}
        activity={activity}
        actors={actors}
        date={activity?.object?.updated_at}
      />
      <Box px={3}>
        <Divider />
      </Box>

      <MediaContent
        cta={
          <Button
            className="pendo_web-expertpanels-contribute"
            component={Link}
            fullWidth={isXs}
            href="/publishing/expert-panels"
            startIcon={<EditSquare />}
            variant="contained"
          >
            {isSmDown ? "Contribute" : "Contribute to an Expert Panel"}
          </Button>
        }
        label={<Typography variant="body1Semibold">Expert Panel</Typography>}
        loading={loading}
        urlMetadata={urlMetadata}
      />

      <ActivityCardFooter
        hideComment
        activity={activity}
        customAction={
          urlMetadata?.url && (
            <Button
              className="pendo_web-feed-card-read-article"
              color="secondary"
              href={urlMetadata?.url}
              startIcon={<OpenInNew />}
              target="_blank"
            >
              Read the article
            </Button>
          )
        }
      />
    </Card>
  );
}
