import { Add } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { useRef, useState } from "react";

import Snackbar from "components/shared/Snackbar";
import getImageDimensions from "lib/common/getImageDimensions";

type Props = {
  buttonText?: string;
  imageMaxHeight?: number;
  imageMaxWidth?: number;
  imageMinHeight?: number;
  imageMinWidth?: number;
  maxFileNameLength?: number;
  maxFileSize?: number;
  onChange?: (file: File) => void;
  subText?: string;
  validFileTypes?: string[];
};

export default function TheDroplet({
  buttonText = "Upload",
  imageMaxHeight = null,
  imageMaxWidth = null,
  imageMinHeight = null,
  imageMinWidth = null,
  maxFileNameLength = 100,
  maxFileSize = 10, // In MB
  onChange = () => null,
  subText = null,
  validFileTypes = null, // Array,
}: Props) {
  const snackbarRef = useRef(null);
  const inputRef = useRef(null);

  const [dropActive, setDropActive] = useState(false);

  const handleFileError = (message) => {
    setDropActive(false);
    inputRef.current.value = "";
    snackbarRef?.current?.callSnackbar(message, "error");
  };

  const handleNewFile = async (file) => {
    if (validFileTypes) {
      const isValidFileType = validFileTypes.includes(file.type);
      if (!isValidFileType) {
        return handleFileError(
          `File must be of type(s): ${validFileTypes.join(", ")}`
        );
      }
    }

    if (maxFileSize) {
      const isSizeValid = file.size / 1048576 <= maxFileSize;
      if (!isSizeValid) {
        return handleFileError(`File size must be less than ${maxFileSize}MB`);
      }
    }

    if (maxFileNameLength) {
      const isNameLengthValid = file.name.length <= maxFileNameLength;
      if (!isNameLengthValid) {
        return handleFileError(
          `File name must be less than ${maxFileNameLength} characters`
        );
      }
    }

    if (
      file.type.includes("image") &&
      (imageMinHeight || imageMaxHeight || imageMinWidth || imageMaxWidth)
    ) {
      const { height, width } = await getImageDimensions(file);

      if (imageMinHeight && height < imageMinHeight) {
        return handleFileError(
          `Image height is smaller than the recommended minimum size. Please upload an image with a height of at least ${imageMinHeight}.`
        );
      }

      if (imageMaxHeight && height > imageMaxHeight) {
        return handleFileError(
          `Image height is larger than the recommended maximum size. Please upload an image with a height less than ${imageMaxHeight}.`
        );
      }

      if (imageMinWidth && width < imageMinWidth) {
        return handleFileError(
          `Image width is smaller than the recommended minimum size. Please upload an image with a width of at least ${imageMinWidth}.`
        );
      }

      if (imageMaxWidth && width > imageMaxWidth) {
        return handleFileError(
          `Image width is larger than the recommended maximum size. Please upload an image with a width less than ${imageMaxWidth}.`
        );
      }
    }

    setDropActive(false);
    onChange(file);
  };

  const handleDrop = (e) => {
    e.preventDefault();

    const file = e.dataTransfer.files[0];

    handleNewFile(file);
  };

  const handleAdd = (e) => {
    if (!e.target.files) return;
    const file = Array.from(e.target.files)[0];

    handleNewFile(file);
  };

  function handleDragEnter(e) {
    e.preventDefault();
    setDropActive(true);
  }

  function handleDragLeave() {
    setDropActive(false);
  }

  return (
    <>
      <Snackbar reference={snackbarRef} />
      <div
        style={{
          width: "100%",
          height: "149px",
          display: "grid",
          justifyContent: "center",
          alignContent: "center",
          gap: "16px",
        }}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={(e) => e.preventDefault()}
        onDrop={handleDrop}
      >
        {dropActive ? (
          <Add sx={{ fontSize: "56px", pointerEvents: "none" }} />
        ) : (
          <>
            <Button
              variant="contained"
              onClick={() => inputRef.current.click()}
            >
              {buttonText}
            </Button>
            {subText && (
              <Typography color="textSecondary" variant="body2">
                {subText}
              </Typography>
            )}
          </>
        )}

        <input
          ref={inputRef}
          hidden
          accept={validFileTypes && validFileTypes.join(",")}
          type="file"
          onChange={handleAdd}
        />
      </div>
    </>
  );
}
