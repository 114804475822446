import { getTimeZones } from "@vvo/tzdb";
import { parseISO } from "date-fns";
import { z } from "zod";

import { FeedActivity } from "lib/motorcade/types";

export const TITLE_MAX_LENGTH = 60;
export const DESCRIPTION_MAX_LENGTH = 140;

export const eventTypeOptions = [
  { label: "In Person", value: "IN_PERSON" },
  { label: "Hybrid", value: "HYBRID" },
  { label: "Virtual", value: "VIRTUAL" },
];

export const buttonTextOptions = [
  "Learn More",
  "Register",
  "RSVP",
  "Secure your spot",
  "Purchase",
];

export const buttonRecordingResourcesOptions = [
  "View recording",
  "View images",
  "View materials",
];

const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const timezones = getTimeZones();

const today = new Date();
today.setHours(0, 0, 0, 0);
const pastDate = new Date();
pastDate.setDate(today.getDate() - 1);

export const initialValues = {
  audience: "general",
  title: "",
  description: "",
  type: "IN_PERSON",
  host: null,
  attachment: null,
  timezone: timezones.find(
    (item) => item.name === localTimeZone || item.group.includes(localTimeZone)
  ),
  startDate: today,
  startTime: "19:00",
  endTime: "",
  cta: "Learn More",
  link: "",
  resourceCta: null,
  resourceLink: null,
};

export const eventValidationSchema = z.object({
  audience: z.string().min(1, { message: "Audience is required" }),
  title: z
    .string()
    .min(1, { message: "Please enter a title" })
    .max(TITLE_MAX_LENGTH, {
      message: `The event title must be at most ${TITLE_MAX_LENGTH} characters`,
    }),
  description: z
    .string()
    .min(1, { message: "Please add a description" })
    .max(DESCRIPTION_MAX_LENGTH, {
      message: `The event description must be at most ${DESCRIPTION_MAX_LENGTH} characters`,
    }),
  type: z.string().min(1, { message: "Type is a required field" }),
  host: z
    .object({
      userId: z.string(),
      name: z.string(),
      avatar: z.string(),
    })
    .nullable(),
  attachment: z
    .any()
    .refine((file) => file?.type?.includes("image"), "Must be an Image.")
    .nullable(),
  timezone: z.object(
    {
      name: z.string().min(1, { message: "Please select a timezone" }),
    },
    { errorMap: () => ({ message: "Please select a timezone" }) }
  ),
  startDate: z.date(),
  startTime: z.string().nullable(),
  endTime: z.string().nullable(),
  cta: z.string().min(1, { message: "Please select text for the button." }),
  link: z
    .string()
    .url({ message: "Link must be a valid url." })
    .min(1, { message: "Please provide a link." }),
  resourceCta: z
    .string()
    .min(1, { message: "Please select text for the Resource button." })
    .nullable(),
  resourceLink: z
    .string()
    .url({ message: "Resouce link must be a valid url." })
    .min(1, { message: "Please provide a link." })
    .nullable(),
});

export type EventValidationSchemaType = z.infer<typeof eventValidationSchema>;

export const getFormInitialValues = (
  activity: FeedActivity & { verb: "event" }
): EventValidationSchemaType => {
  if (!activity) return initialValues;

  const values = {
    audience: activity.audience,
    title: activity.object.data.title,
    description: activity.object.data.description,
    type: activity.object.data.type,
    host: activity?.host?.id
      ? {
          ...activity?.host?.data,
          userId: activity?.host?.id,
          role: activity?.host?.data?.type,
        }
      : null,
    attachment: null,
    timezone: timezones.find(
      (item) =>
        item.name === activity.object.data.timezone ||
        item.group.includes(localTimeZone)
    ),
    startDate: parseISO(activity.object.data.startDate as string),
    startTime: activity.object.data.startTime,
    endTime: activity.object.data.endTime ?? "",
    cta: activity.object.data.cta,
    link: activity.object.data.link,
    resourceCta: activity.object.data.resourceCta ?? null,
    resourceLink: activity.object.data.resourceLink ?? null,
  } as EventValidationSchemaType;
  return values;
};
