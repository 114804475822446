import { LoadingButton } from "@mui/lab";
import { Stack, Typography } from "@mui/material";

type Props = {
  answerCount: number;
  handleJoinClick: () => void;
  loading: boolean;
  open: boolean;
};

export default function JoinGroupCta({
  answerCount,
  handleJoinClick,
  loading,
  open,
}: Props) {
  return (
    <Stack
      alignItems="center"
      className="pendo_desktop-groupsep-join-group"
      direction="row"
    >
      <LoadingButton
        className="pendo_web-groups-ep-join"
        loading={loading}
        sx={(theme) => ({
          ...theme.typography.body2,
          padding: 0,
          textTransform: "none",
          color: theme.palette.secondary.main,

          "&:hover": {
            backgroundColor: "transparent",
          },
          "&.small-label": {
            "& $label": {
              ...theme.typography.body2,
            },
          },
        })}
        onClick={handleJoinClick}
      >
        Join Group&nbsp;
      </LoadingButton>
      <Typography color="textSecondary" component="span" variant="body2">
        {answerCount
          ? `to view all answers${open ? " and contribute." : "."}`
          : "to be the first to respond."}
      </Typography>
    </Stack>
  );
}
