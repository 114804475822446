import { useQuery } from "@apollo/client";

import { FEED_GROUP_FULL } from "graphql/groups/queries";

import { MEMBER_PAGE_SIZE } from "./useGroupMembers";

type Props =
  | {
      groupId: string;
      slug?: string;
    }
  | {
      groupId?: string;
      slug: string;
    };

export default function useGroupFull({ groupId, slug }: Props) {
  const { data, error, loading, refetch } = useQuery(FEED_GROUP_FULL, {
    variables: {
      input: {
        groupId,
        slug,
      },
      first: MEMBER_PAGE_SIZE,
    },
  });

  const group = data?.feedGroup;

  return {
    error,
    group,
    loading,
    refetch,
  };
}
