import PhotoCameraOutlined from "@mui/icons-material/PhotoCameraOutlined";
import { IconButton } from "@mui/material";
import { Dispatch, SetStateAction, useRef } from "react";

import Snackbar from "components/shared/Snackbar";
import cls from "lib/common/cls";

type Props = {
  className?: string;
  disabled?: boolean;
  setMediaAttachmentFile: Dispatch<SetStateAction<File>>;
};

export default function InsertMediaButton({
  className,
  disabled = false,
  setMediaAttachmentFile,
}: Props) {
  const snackbarRef = useRef(null);
  const mediaInputRef = useRef<HTMLInputElement>();

  const handleMediaAdd = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = Array.from(e.target.files)[0] as File;
    const isSizeValid = file.size / 1000000 <= 10;
    const isNameLengthValid = file.name.length <= 100;
    if (!isSizeValid) {
      return snackbarRef?.current?.callSnackbar(
        "File size must be less than 10MB",
        "error"
      );
    }
    if (!isNameLengthValid) {
      return snackbarRef?.current?.callSnackbar(
        "File name must be less than 100 characters",
        "error"
      );
    }
    setMediaAttachmentFile(file);
  };

  return (
    <>
      <Snackbar reference={snackbarRef} />

      <input
        ref={mediaInputRef}
        accept="image/gif, image/jpeg, image/png"
        data-max="1"
        id="icon-button-media"
        style={{ position: "fixed", left: "-10000px" }}
        type="file"
        onChange={(e) => {
          handleMediaAdd(e);
          mediaInputRef.current.value = "";
        }}
      />
      <IconButton
        aria-label="upload media"
        className={cls([className])}
        disabled={disabled}
        size="large"
        title="Attach"
        onClick={() => mediaInputRef.current.click()}
      >
        <PhotoCameraOutlined fontSize="small" />
      </IconButton>
    </>
  );
}
