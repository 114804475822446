import pluralize from "lib/common/pluralize";

export default function daysLeft({ endDate }) {
  const today = new Date();
  const end = new Date(endDate);
  const difference = end.getTime() - today.getTime();

  if (difference < 0) {
    return 0;
  }

  const minutes = Math.ceil(difference / 1000 / 60);
  const hours = Math.ceil(difference / (1000 * 60 * 60));
  const days = Math.ceil(difference / (1000 * 60 * 60 * 24));

  if (minutes < 60) {
    return `${minutes} ${pluralize("minute", minutes, "s")} left`;
  }
  if (hours < 24) {
    return `${hours} ${pluralize("hour", hours, "s")} left`;
  }
  if (days < 7 || (days >= 7 && days % 7 !== 0)) {
    return `${days} ${pluralize("day", days, "s")} left`;
  }
  if (days % 7 === 0) {
    return `${days / 7} ${pluralize("week", days / 7, "s")} left`;
  }
}
