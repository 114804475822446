import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Radio,
  Skeleton,
  SxProps,
  Typography,
} from "@mui/material";
import { useMemo } from "react";

import Avatar from "components/shared/Avatar";
import DialogHeader from "components/shared/DialogHeader";
import useGroups from "hooks/useGroups";
import useMyGroups from "hooks/useMyGroups";
// @todo update with feed group graph type
import { Group } from "types/group";

import { ActivityEditorData } from "../ActivityEditorDialog";

type Props = {
  open: boolean;
  onClose: () => void;
  activityEditorData: ActivityEditorData;
  handleSaveGroupAudience: (newGroupAudience: Group) => void;
  groupType: "PUBLIC" | "PRIVATE";
};

type GroupListProps = {
  title: string;
  groups: Group[];
  groupType: "PUBLIC" | "PRIVATE";
  groupAudience: Group;
  handleSaveGroupAudience: (newGroupAudience: Group) => void;
  loading: boolean;
  sx?: SxProps;
};

function GroupList({
  title,
  groups,
  groupType,
  groupAudience,
  handleSaveGroupAudience,
  loading,
  sx,
}: GroupListProps) {
  return (
    <>
      {loading && (
        <List disablePadding sx={sx}>
          {[...new Array(6)].map((_, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <ListItemButton key={i} sx={{ py: 1, px: 0 }}>
              <Box display="flex" width="220px">
                <Skeleton height={40} variant="circular" width={40} />

                <Box flexGrow={1} ml={1.5} py={1}>
                  <Skeleton width="100%" />
                </Box>
              </Box>
            </ListItemButton>
          ))}
        </List>
      )}

      {!loading && groups.length > 0 && (
        <>
          <Typography mb={1} variant="subtitle1">
            {title}
          </Typography>

          <List disablePadding sx={sx}>
            {groups.map((group) => {
              const { id, name, imageUrl } = group;
              const onClick = () => handleSaveGroupAudience(group);
              return (
                <ListItemButton
                  key={id}
                  sx={{ py: 1, px: 0 }}
                  onClick={onClick}
                >
                  <ListItemAvatar>
                    <Avatar alt={name} src={imageUrl} />
                  </ListItemAvatar>

                  <ListItemText
                    primary={name}
                    primaryTypographyProps={{ noWrap: true }}
                    style={{ paddingRight: "40px" }}
                  />
                  <ListItemSecondaryAction>
                    <Radio
                      checked={groupAudience?.id === id}
                      edge="end"
                      onClick={onClick}
                    />
                  </ListItemSecondaryAction>
                </ListItemButton>
              );
            })}
          </List>
        </>
      )}

      {!loading && groups.length === 0 && groupType === "PRIVATE" && (
        <Typography>You are not a member of a Private Group</Typography>
      )}
    </>
  );
}

export default function GroupAudienceDialog({
  open,
  onClose,
  activityEditorData,
  groupType = "PUBLIC",
  handleSaveGroupAudience,
}: Props) {
  const { group: groupAudience } = activityEditorData;
  const {
    myGroups,
    myPublicGroups,
    myPrivateGroups,
    loading: loadingMyGroups,
  } = useMyGroups();
  const { groups, loading: loadingAllGroups } = useGroups();

  const otherPublicGroups = useMemo(
    () =>
      groups.filter(
        ({ id, type }) =>
          !myGroups.find((myGroup) => myGroup.id === id) && type !== "PRIVATE"
      ),
    [myGroups, groups]
  );

  return (
    <Dialog fullWidth open={open} transitionDuration={0} onClose={onClose}>
      <DialogHeader title="Select a Group" onClose={onClose} />

      <DialogContent dividers sx={{ px: "14px", py: "12px" }}>
        {groupType === "PUBLIC" && (
          <GroupList
            groupAudience={groupAudience}
            groupType={groupType}
            groups={myPublicGroups}
            handleSaveGroupAudience={handleSaveGroupAudience}
            loading={loadingMyGroups}
            sx={{ mb: 3 }}
            title="My Groups"
          />
        )}

        {groupType === "PUBLIC" && (
          <GroupList
            groupAudience={groupAudience}
            groupType={groupType}
            groups={otherPublicGroups}
            handleSaveGroupAudience={handleSaveGroupAudience}
            loading={loadingAllGroups}
            title="All Groups"
          />
        )}

        {groupType === "PRIVATE" && (
          <GroupList
            groupAudience={groupAudience}
            groupType={groupType}
            groups={myPrivateGroups}
            handleSaveGroupAudience={handleSaveGroupAudience}
            loading={loadingMyGroups}
            title="My Groups"
          />
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Back</Button>
        {(groupType === "PUBLIC" ||
          (groupType === "PRIVATE" && myPrivateGroups.length > 0)) && (
          <Button variant="contained" onClick={onClose}>
            Save Changes
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
