import {
  Delete,
  Edit,
  Flag,
  InsertLink,
  MoreHoriz,
  PushPin,
} from "@mui/icons-material";
import { Menu, MenuItem, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useRouter } from "next/router";
import { useState } from "react";

import ActivityEditorDialog from "components/feed/ActivityEditor/ActivityEditorDialog";
import DeleteDialog from "components/feed/dialogs/DeleteDialog";
import ReportActivityDialog from "components/feed/dialogs/ReportDialog/ReportActivity";
import canPinToGroup from "components/groups/GroupPagePinTooltip/canPinToGroup";
import useIdentity from "hooks/useIdentity";
import useMe from "hooks/useMe";
import { useApp } from "lib/common/appProvider";
import cls from "lib/common/cls";
import {
  DELETABLE_ACTIVITY_VERBS,
  EDITABLE_ACTIVITY_VERBS,
  PINNABLE_ACTIVITY_VERBS,
  READABLE_ACTIVITY_VERB,
  REPORTABLE_ACTIVITY_VERBS,
} from "lib/motorcade/constants";
import usePinActivity from "lib/motorcade/hooks/usePinActivity";
import usePinFeed from "lib/motorcade/hooks/usePinFeed";
import { AlertCallback, FeedActivity } from "lib/motorcade/types";
import baseUrl from "lib/site/baseUrl";

function PinActivityButton({ activity, alertCallback, handleClose, group }) {
  const { activities: pinnedActivities } = usePinFeed(
    group?.restrictedId ?? group?.id
  );
  const { pinActivity, unpinActivity } = usePinActivity({
    activity,
    alertCallback,
  });

  const isPinned = !!pinnedActivities?.find(
    (pinnedActivity) => pinnedActivity?.id === activity?.id
  );

  const handlePin = () => {
    const postType = activity.verb === "post" ? "POST" : "POLL";
    if (isPinned) {
      unpinActivity({ id: activity?.foreign_id, postType });
    } else {
      pinActivity({ id: activity?.foreign_id, postType });
    }
    handleClose();
  };

  return (
    <MenuItem
      className={
        isPinned ? "pendo_web-group-unpin-post" : "pendo_web-group-pin-post"
      }
      onClick={handlePin}
    >
      <PushPin />
      <Typography variant="body2">
        {isPinned ? "Unpin from Featured" : "Pin to Featured"}
        <br />
        <Typography color="textSecondary" component="span" variant="inherit">
          {isPinned
            ? "Only Group Leaders can unpin posts"
            : "Only Group Leaders can pin posts"}
        </Typography>
      </Typography>
    </MenuItem>
  );
}

type Props = {
  activity: FeedActivity;
  iconColor?: "primary" | "secondary";
};

export default function ActivityCardContextMenu({
  activity,
  iconColor = "secondary",
}: Props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isReportDialogOpen, setIsReportDialogOpen] = useState(false);

  const { role } = useIdentity();
  const { id: meId, myGroups } = useMe();
  const router = useRouter();

  const snackbarRef = useApp("snackbarRef");
  const alertCallback: AlertCallback = ({ message, type, title }) => {
    snackbarRef?.current?.callSnackbar(message, type, title);
  };

  const group = myGroups?.groups?.find(
    (myGroup) => myGroup.id === activity?.groupRef?.id
  );

  const canPin = canPinToGroup({
    groupId: activity?.groupRef?.id,
    groupsILead: myGroups?.groupsILead,
  });

  const activityActor = activity.actor;
  const isMyActivity = activityActor.id === meId;

  const canDelete = role === "ADMIN" && activity?.foreign_id;
  const showDeleteButton =
    canDelete && DELETABLE_ACTIVITY_VERBS.includes(activity.verb);
  const showEditButton =
    (isMyActivity || (role === "ADMIN" && activity.verb === "event")) &&
    EDITABLE_ACTIVITY_VERBS.includes(activity.verb);
  const showPinnedButton =
    PINNABLE_ACTIVITY_VERBS.includes(activity.verb) &&
    activity?.groupRef?.id &&
    router.pathname.includes("groups") &&
    canPin;
  const showReportButton =
    !isMyActivity &&
    activity?.host?.id !== meId &&
    REPORTABLE_ACTIVITY_VERBS.includes(activity.verb);

  const handleClose = (callbackFn?: () => void) => {
    setAnchorEl(null);
    if (callbackFn) callbackFn();
  };

  const handleCopyPostLink = () => {
    let activityId = activity?.id;

    switch (activity.verb) {
      case "expertpanel":
        activityId = activity?.parentId;
        break;
    }

    const url = `${window.location.origin}${baseUrl(
      `/activities/${activityId}`
    )}`;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        snackbarRef?.current?.callSnackbar("Copied to clipboard", "success");
        handleClose();
      })
      .catch((err) => {
        snackbarRef?.current?.callSnackbar(err.message, "error");
        handleClose();
      });
  };

  return (
    <>
      <IconButton
        aria-label="context-menu"
        color={iconColor}
        onClick={(e) => {
          e.preventDefault();
          setAnchorEl(e.currentTarget);
        }}
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClick={(e) => e.stopPropagation()}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          className="pendo_web-post-menu-copy-link"
          onClick={handleCopyPostLink}
        >
          <InsertLink sx={{ mr: 1 }} />
          <Typography variant="body2">Copy link to post</Typography>
        </MenuItem>

        {showEditButton && (
          <MenuItem
            className={cls([
              activity?.verb === "post" && "pendo_web-post-menu-edit",
            ])}
            onClick={() => setIsEditDialogOpen(true)}
          >
            <Edit sx={{ mr: 1 }} />
            <Typography variant="body2">{`Edit ${
              READABLE_ACTIVITY_VERB[activity?.verb]
            }`}</Typography>
          </MenuItem>
        )}

        {showDeleteButton && (
          <MenuItem onClick={() => setIsDeleteDialogOpen(true)}>
            <Delete sx={{ mr: 1 }} />
            <Typography variant="body2">{`Delete ${
              READABLE_ACTIVITY_VERB[activity?.verb]
            }`}</Typography>
          </MenuItem>
        )}

        {showReportButton && (
          <MenuItem
            className="pendo_web-post-menu-report"
            onClick={() => setIsReportDialogOpen(true)}
          >
            <Flag sx={{ mr: 1 }} />
            <Typography variant="body2">Report content</Typography>
          </MenuItem>
        )}

        {showPinnedButton && (
          <PinActivityButton
            activity={activity}
            alertCallback={alertCallback}
            group={group}
            handleClose={handleClose}
          />
        )}
      </Menu>

      <DeleteDialog
        activity={activity}
        handleClose={() => handleClose(() => setIsDeleteDialogOpen(false))}
        isOpen={isDeleteDialogOpen}
      />

      <ReportActivityDialog
        activity={activity}
        isOpen={isReportDialogOpen}
        onClose={() => handleClose(() => setIsReportDialogOpen(false))}
      />

      {isEditDialogOpen && (
        <ActivityEditorDialog
          activity={activity}
          initialEditor={activity?.verb === "event" ? "event" : "post"}
          open={isEditDialogOpen}
          onClose={() => handleClose(() => setIsEditDialogOpen(false))}
        />
      )}
    </>
  );
}
