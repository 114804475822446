import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

import { Dispatch, SetStateAction } from "react";

import Close from "components/icons/Close";

import PollOptionAccordion from "./PollOptionAccordion";

type Props = {
  pollId: string;
  open: boolean;
  options: string[];
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export default function PollVotesDialog({
  pollId,
  open,
  options,
  setOpen,
}: Props) {
  return (
    <Dialog fullWidth open={open}>
      <DialogTitle>
        <Box alignItems="center" display="flex" gap={2}>
          <IconButton
            className="button-post-cancel"
            color="inherit"
            size="large"
            sx={{
              display: { xs: "flex", sm: "none" },
            }}
            onClick={() => setOpen(false)}
          >
            <Close />
          </IconButton>
          <Typography variant="h3">Votes</Typography>
          <IconButton
            className="button-post-cancel"
            color="inherit"
            size="large"
            sx={{
              display: { xs: "none", sm: "flex" },
              ml: "auto",
            }}
            onClick={() => setOpen(false)}
          >
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent dividers>
        {options.map((option) => (
          <PollOptionAccordion key={option} option={option} pollId={pollId} />
        ))}
      </DialogContent>
    </Dialog>
  );
}
