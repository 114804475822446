import { Box, Card, CardContent, Skeleton } from "@mui/material";

export default function EditorsChoiceCardLoading() {
  return (
    <Card sx={{ padding: "10px 30px" }}>
      <Skeleton
        height={40}
        sx={{
          display: { xs: "none", sm: "block" },
          padding: "8px 14px",
          margin: "10px 0 12px 0",
        }}
        variant="rectangular"
        width="17%"
      />
      <CardContent sx={{ pt: 1, pb: 0, pl: 0 }}>
        <Box display="flex" sx={{ flexDirection: { xs: "column", sm: "row" } }}>
          <Skeleton
            height={184}
            sx={{ width: { xs: "100%", sm: "40%" }, mr: 2 }}
            variant="rectangular"
          />
          <Box sx={{ minWidth: "calc(51% - 10px)", mt: 2 }}>
            <Skeleton height="30%" variant="text" width="80%" />
            <Skeleton height="15%" variant="text" width="100%" />
            <Box alignItems="center" display="flex" mt={2}>
              <Skeleton
                height={40}
                sx={{ mr: 1.5 }}
                variant="circular"
                width={40}
              />
              <Skeleton height="15%" variant="text" width="35%" />
            </Box>
          </Box>
        </Box>
      </CardContent>
      <Box mb={1} mt={2}>
        <Skeleton
          height={40}
          sx={{ width: { xs: "100%", sm: "17%" } }}
          variant="rectangular"
        />
      </Box>
    </Card>
  );
}
