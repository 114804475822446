import { Plugin, PluginKey } from "prosemirror-state";

export const pluginKey = new PluginKey("watch");

/**
 * Watches the document content and runs the onIsDirty function if
 * the editor content has changed from its initial content. This is
 * useful for determing if the user has made changes to some previously
 * saved content e.g. editing a comment. Other functionality that watches
 * the content could also be added here if needed.
 * @param {function} onIsDirty
 * @returns ProseMirrorPlugin
 */
export default function watch({
  onIsDirty,
}: {
  onIsDirty: (isDirty: boolean) => void;
}) {
  return new Plugin({
    key: pluginKey,

    state: {
      init(config, instance) {
        return {
          initialDoc: instance.doc,
        };
      },

      apply(tr, prev) {
        return prev;
      },
    },

    view: () => {
      return {
        update(view, prevState) {
          const changed = !view.state.doc.eq(prevState.doc);

          if (changed) {
            const { initialDoc } = pluginKey.getState(view.state);
            const isDirty = !view.state.doc.eq(initialDoc);
            onIsDirty(isDirty);
          }
        },
      };
    },
  });
}
