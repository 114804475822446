import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Chip,
  Skeleton,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";

import ExpandMore from "components/icons/ExpandMore";
import ProfilePopoverWrapper from "components/profile/ProfilePopoverWrapper";
import pluralize from "lib/common/pluralize";
import { GraphUser } from "lib/motorcade/types";

type Props = {
  loading: boolean;
  totalVotes: number;
  option: string;
  users: GraphUser[];
};

export default function OptionAccordion({
  loading,
  totalVotes,
  option,
  users,
}: Props) {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Box display="flex" justifyContent="space-between" pr={1} width="100%">
          <Typography variant="subtitle2">{option}</Typography>
          {loading ? (
            <Skeleton height="20px" variant="rectangular" width="68px" />
          ) : (
            <Chip
              label={`${totalVotes} ${pluralize("vote", totalVotes)}`}
              size="small"
            />
          )}
        </Box>
      </AccordionSummary>
      {totalVotes > 0 && (
        <AccordionDetails sx={{ maxHeight: "342px", overflowY: "auto" }}>
          <Stack gap={1}>
            {users.map((user) => (
              <Box
                key={user?.node?.id}
                alignItems="center"
                display="flex"
                gap={1}
                width="100%"
              >
                {ProfilePopoverWrapper(
                  <Avatar
                    alt={user?.node?.snapshot.name}
                    src={user?.node?.snapshot.avatar}
                  />,
                  user?.node?.id
                )}
                <div>
                  {ProfilePopoverWrapper(
                    <Typography variant="subtitle2">
                      {user?.node?.snapshot.name}
                    </Typography>,
                    user?.node?.id
                  )}

                  <Typography color="textSecondary" variant="body2">
                    {user?.node?.snapshot.companyName}
                  </Typography>
                </div>
              </Box>
            ))}
          </Stack>
        </AccordionDetails>
      )}
    </Accordion>
  );
}
