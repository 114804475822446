import ProfilePopover from "components/shared/ProfilePopover";

export default function ProfilePopoverWrapper(target, userId, props) {
  return userId ? (
    <ProfilePopover
      dataSource="platform"
      target={target}
      userId={userId}
      {...props}
    />
  ) : (
    target
  );
}
