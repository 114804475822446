import { Card, CardContent, Typography } from "@mui/material";
import { Box } from "@mui/system";

import ActivityCardHeader from "components/feed/FeedCard/base/ActivityCardHeader";
import Markdown from "components/markdown/Markdown";
import Truncate from "components/shared/Truncate";
import Poll from "components/ui/Poll";
import useMe from "hooks/useMe";
import { useApp } from "lib/common/appProvider";
import usePoll from "lib/motorcade/hooks/usePoll";
import { AlertCallback, FeedActivity } from "lib/motorcade/types";

import ActivityCardFooter from "../base/ActivityCardFooter";

function getPendoClass(audience: string, isPinned: boolean) {
  if (isPinned) return "pendo_web-group-pinned-poll-click";

  switch (audience) {
    case "general":
      return "pendo_web-poll-everyone";

    case "group":
      return "pendo_web-group-poll";

    case "profile":
      return "pendo_web-poll-followers";

    default:
      return "pendo_web-poll-everyone";
  }
}

type Props = {
  activity: FeedActivity & {
    verb: "poll";
  };
  isPinned: boolean;
};

export default function PollCard({ activity, isPinned = false }: Props) {
  const { id: meId } = useMe();

  const snackbarRef = useApp("snackbarRef");
  const alertCallback: AlertCallback = ({ message, type, title }) => {
    snackbarRef?.current?.callSnackbar(message, type, title);
  };

  const { addVote, removeVote } = usePoll(activity, alertCallback);

  const { audience, closeAt, deleted, options, question, results } =
    activity.object.data;

  const isAuthor = meId === activity.actor.id;
  const caption = isAuthor
    ? "Only you can see how people vote."
    : "Only the poll creator can see how you vote.";
  const totalVotes = activity?.reaction_counts?.vote || 0;
  const optionsData = options.map((option, index) => {
    const votes = results?.[index];

    return {
      value: option,
      votePercentage: Math.round((votes / totalVotes) * 100) || 0,
      voters:
        activity?.latest_reactions?.vote
          ?.filter((vote) => vote.data.choices[0] === option)
          ?.map((vote) => vote.user) || [],
      votes,
    };
  });
  const selectedVote = activity?.own_reactions?.vote?.[0]?.data?.choices[0];
  const status = new Date(closeAt) > new Date() ? "active" : "ended";
  const showRemoveVote = status === "active" && !!selectedVote;
  const showVotePercentage = isAuthor || status === "ended" || !!selectedVote;

  const pendoClass = getPendoClass(audience, isPinned);

  const handleVoteChange = (vote) => {
    addVote.mutate(vote);
  };

  const handleRemoveVote = () => {
    removeVote.mutate();
  };

  return (
    <Card className={pendoClass} sx={{ height: "100%" }}>
      <ActivityCardHeader actionText="posted to" activity={activity} />
      <CardContent sx={{ px: 3 }}>
        <Box mb={!isPinned && 2}>
          <Truncate expandable={!isPinned} maxLines={isPinned ? 1 : 3}>
            <Typography variant="body2">
              <Markdown markdown={activity.object.data.postText} />
            </Typography>
          </Truncate>
        </Box>

        <Poll
          caption={caption}
          closeAt={closeAt}
          closed={status === "ended"}
          condensed={isPinned}
          deleted={deleted}
          handleRemoveVote={handleRemoveVote}
          handleVoteChange={handleVoteChange}
          options={optionsData}
          pollId={activity.foreign_id}
          question={question}
          selectedVote={selectedVote}
          showRemoveVote={showRemoveVote}
          showVoteDetails={isAuthor}
          showVotePercentage={showVotePercentage}
          totalVotes={totalVotes}
        />
      </CardContent>
      {!isPinned && <ActivityCardFooter activity={activity} />}
    </Card>
  );
}
