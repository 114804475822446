import {
  Avatar,
  Button,
  CardActions,
  CardHeader,
  Divider,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import Link from "next/link";

import ExpertPanelAnswerLikeCount from "components/expert-panels/ExpertPanelAnswerLikeCount";
import ExpertPanelAnswerReactionButton from "components/expert-panels/ExpertPanelAnswerReactionButton";
import ExpertPanelTagChip from "components/expert-panels/ExpertPanelTagChip";
import { getStatusTag } from "components/expert-panels/helpers";
import ActivityCardHeader from "components/feed/FeedCard/base/ActivityCardHeader";
import ReactionCardContextMenu from "components/feed/ReactionCardContextMenu";
import ProfilePopoverWrapper from "components/profile/ProfilePopoverWrapper";
import Truncate from "components/shared/Truncate";
import useMe from "hooks/useMe";
import pluralize from "lib/common/pluralize";
import { FeedActivity } from "lib/motorcade/types";

type Props = {
  activity: FeedActivity & {
    verb: "community_expertpanel_answer";
  };
};

export default function CommunityEPAnswerCard({ activity }: Props) {
  const me = useMe();
  const link = `/publishing/expert-panels/${activity.object.foreign_id}`;

  const totalAnswers =
    activity.object.reaction_counts?.community_expertpanel_answer || 0;
  const totalLikes = activity?.reaction?.children_counts?.like || 0;

  const statusTag = getStatusTag({ dataSource: "feed", question: activity });

  return (
    <Card className="pendo_web-ep-memberanswered-card">
      <ActivityCardHeader
        actionText="answered an Expert Panel question"
        activity={activity}
      />
      <CardContent sx={{ px: 3 }}>
        {(statusTag || activity.object.object.data.topicTagLabel) && (
          <Box display="flex" gap={1} pb={1}>
            {statusTag && <ExpertPanelTagChip tag={statusTag} />}
            {activity.object.object.data.topicTagLabel && (
              <ExpertPanelTagChip
                tag={activity.object.object.data.topicTagLabel}
              />
            )}
          </Box>
        )}

        <Typography color="textSecondary" variant="body2Semibold">
          {totalAnswers} {pluralize("Expert", totalAnswers, "s")} answered
        </Typography>

        <Truncate maxLines={4} sx={{ pb: 2, pt: 1 }}>
          <Typography variant="body1Semibold">
            {activity.object.object.data.question}
          </Typography>
        </Truncate>

        <Card
          className="pendo_web-ep-membersanswer"
          elevation={0}
          sx={{ backgroundColor: (theme) => theme.palette.grey[100] }}
        >
          <CardHeader
            action={<ReactionCardContextMenu reaction={activity.reaction} />}
            avatar={ProfilePopoverWrapper(
              <Avatar src={activity.reaction.user.data.avatar} />,
              activity.reaction.user.id
            )}
            subheader={
              <Typography color="textSecondary" variant="caption">
                {activity.reaction.user.data.title},{" "}
                {activity.reaction.user.data.company}
              </Typography>
            }
            sx={{ pb: 0 }}
            title={
              <Box display="flex" gap={1}>
                {ProfilePopoverWrapper(
                  <Typography variant="body2Semibold">
                    {activity.reaction.user.data.name}
                  </Typography>,
                  activity.reaction.user.id
                )}
                •
                <Typography color="textSecondary" variant="body2Semibold">
                  Following
                </Typography>
              </Box>
            }
          />

          <CardContent sx={{ py: 0 }}>
            <Truncate buttonBackgroundColor="#f4f4f4" maxLines={4}>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: activity.reaction.data.body,
                }}
                variant="body2"
              />
            </Truncate>
          </CardContent>

          <CardActions sx={{ pt: 0 }}>
            <ExpertPanelAnswerReactionButton answer={activity} context="feed" />
            <ExpertPanelAnswerLikeCount count={totalLikes} />
          </CardActions>
        </Card>

        <Box
          className="pendo_web-expertpanels-answerthisquestion"
          component={Link}
          display="block"
          href={link}
          py={2}
        >
          <Box
            alignItems="center"
            border="1px solid rgba(0, 0, 0, 0.50)"
            borderRadius="4px"
            display="flex"
            gap={1}
            p={1}
          >
            <Avatar src={me?.profile?.avatar} sx={{ height: 24, width: 24 }} />

            <Typography color="textSecondary" variant="body2">
              Contribute to an Expert Panel
            </Typography>
          </Box>
        </Box>

        <Divider />

        <Button
          className="pendo_web-expertpanels-viewmoreanswers"
          component={Link}
          href={link}
          sx={(theme) => ({
            mt: 2,
            ...theme.typography.body2,
            color: theme.palette.text.secondary,
          })}
        >
          View other responses
        </Button>
      </CardContent>
    </Card>
  );
}
