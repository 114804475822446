import { LoadingButton } from "@mui/lab";
import { Button, CardHeader, Menu, Typography } from "@mui/material";
import { Box } from "@mui/system";

import People from "components/icons/People";
import Avatar from "components/shared/Avatar";

type Props = {
  anchorEl: null | HTMLElement;
  group: {
    name: string;
    imageUrl: string;
    totalFollowers: number;
  };
  handleClose: () => void;
  handleJoinGroup: () => void;
  loading: boolean;
  open: boolean;
};

export default function GroupJoinMenu({
  anchorEl,
  group,
  handleClose,
  handleJoinGroup,
  loading,
  open,
}: Props) {
  return (
    <Menu
      MenuListProps={{
        "aria-labelledby": "join-group-button",
        sx: { p: 2 },
      }}
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "center",
        vertical: "bottom",
      }}
      id="join-group-menu"
      open={open}
      transformOrigin={{
        horizontal: "center",
        vertical: "top",
      }}
      onClose={handleClose}
    >
      <CardHeader
        avatar={
          <Avatar
            alt={group.name}
            height={60}
            src={group.imageUrl}
            sx={{ height: 60, width: 60 }}
            width={60}
          >
            {group.name[0]}
          </Avatar>
        }
        subheader={
          <Typography color="textSecondary" variant="caption">
            <Box alignItems="center" display="flex" gap={1}>
              <People style={{ fontSize: "24px" }} />
              {group.totalFollowers}
            </Box>
          </Typography>
        }
        sx={{ mb: 2, p: 0 }}
        title={<Typography fontWeight="bold">{group.name}</Typography>}
      />

      <Typography sx={{ mb: 3 }} variant="body2">
        Are you sure you want to join the Group?
      </Typography>

      <Box display="flex" flexDirection="column" gap={1}>
        <LoadingButton
          fullWidth
          className="pendo_web-groups-post-join-confirm"
          loading={loading}
          variant="contained"
          onClick={handleJoinGroup}
        >
          Yes, I want to join
        </LoadingButton>

        <Button fullWidth onClick={handleClose}>
          Not now
        </Button>
      </Box>
    </Menu>
  );
}
