import { DateRange } from "@mui/icons-material";
import { IconButton } from "@mui/material";

type Props = {
  onClick: () => void;
};

export default function CreateEventButton({ onClick }: Props) {
  return (
    <IconButton size="large" title="Add Event" onClick={onClick}>
      <DateRange fontSize="small" />
    </IconButton>
  );
}
