import GroupJoinMenu from "components/ui/GroupJoinMenu";
import useGroup from "hooks/useGroup";
import useJoinGroup from "hooks/useJoinGroup";

type Props = {
  anchorEl: null | HTMLElement;
  handleClose: () => void;
  id: string;
  open: boolean;
};

export default function GroupJoinMenuWrapper({
  anchorEl,
  handleClose,
  id,
  open,
}: Props) {
  const { group, loading } = useGroup({ groupId: id });
  const { handleJoin, isJoining } = useJoinGroup({ id });

  if (loading) return null;

  const handleJoinGroup = async () => {
    await handleJoin();
    handleClose();
  };

  return (
    <GroupJoinMenu
      anchorEl={anchorEl}
      group={group}
      handleClose={handleClose}
      handleJoinGroup={handleJoinGroup}
      loading={isJoining}
      open={open}
    />
  );
}
