import { Box, Divider, Skeleton, Stack, Typography } from "@mui/material";

import Truncate from "components/shared/Truncate";

export default function AnswerLoading() {
  return (
    <Box mt="16px">
      <div>
        <Stack alignItems="center" direction="row" mb={1.5} spacing={1}>
          <Skeleton height={40} variant="circular" width={40} />
          <div>
            <Stack alignItems="center" direction="row">
              <Typography variant="caption">
                <Skeleton width={200} />
              </Typography>
            </Stack>

            <Typography variant="caption">
              <Skeleton width={100} />
            </Typography>
          </div>
        </Stack>

        <Truncate maxLines={3} mt={1}>
          <Typography component="span" variant="body2">
            <Skeleton />
          </Typography>
        </Truncate>
      </div>

      <Divider sx={{ mt: 2 }} />
    </Box>
  );
}
