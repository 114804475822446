import { Dialog } from "@mui/material";
import { Dispatch, SetStateAction } from "react";

import { Audience as AudienceType } from "lib/motorcade/types";
// @todo update with feed group graph type
import { Group } from "types/group";

import AudienceDialogContent from "./AudienceDialogContent";
import { ActivityEditorData } from "../ActivityEditorDialog";

type Props = {
  activityEditorData: ActivityEditorData;
  open: boolean;
  setActivityEditorData: Dispatch<SetStateAction<ActivityEditorData>>;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
  excludeOptions: string[];
};

export default function AudienceDialog({
  open,
  onClose,
  activityEditorData,
  setActivityEditorData,
  setIsDirty,
  excludeOptions,
}: Props) {
  const handleSaveAudience = (newAudience: AudienceType) => {
    if (newAudience !== "group") {
      setActivityEditorData((value) => ({
        ...value,
        audience: newAudience,
        group: undefined,
      }));
    }
    setIsDirty(true);
    onClose();
  };

  const handleSaveGroupAudience = (group: Group) => {
    setActivityEditorData((value) => ({
      ...value,
      audience: "group",
      group,
    }));
    setIsDirty(true);
    onClose();
  };

  return (
    <Dialog maxWidth="sm" open={open} transitionDuration={0} onClose={onClose}>
      <AudienceDialogContent
        activityEditorData={activityEditorData}
        excludeOptions={excludeOptions}
        handleSaveAudience={handleSaveAudience}
        handleSaveGroupAudience={handleSaveGroupAudience}
        onClose={onClose}
      />
    </Dialog>
  );
}
