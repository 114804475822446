import { Delete, Edit, MoreHoriz } from "@mui/icons-material";
import {
  Box,
  Fab,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";

import Poll from "components/ui/Poll";
import { PollCollection } from "lib/motorcade/types";

import { ActivityEditorData } from "./ActivityEditorDialog";

import DeletePollDialog from "../dialogs/DeletePollDialog";

type Props = {
  poll: PollCollection;
  isEditing: boolean;
  setActivityEditorData: Dispatch<SetStateAction<ActivityEditorData>>;
  setIsDirty: (value: boolean) => void;
  handleOpenPollEditor: () => void;
};

export default function PollPreview({
  poll,
  isEditing,
  setActivityEditorData,
  setIsDirty,
  handleOpenPollEditor,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [deletePollDialogOpen, setDeletePollDialogOpen] = useState(false);

  const handleCloseDeletePollDialog = () => {
    setAnchorEl(null);
    setDeletePollDialogOpen(false);
  };

  const handleDeletePollData = () => {
    handleCloseDeletePollDialog();

    if (isEditing) {
      setIsDirty(true);
      setActivityEditorData((prevState) => ({
        ...prevState,
        poll: {
          data: {
            ...poll?.data,
            deleted: true,
          },
        } as PollCollection,
      }));
    } else {
      setActivityEditorData((prevState) => ({
        ...prevState,
        poll: undefined,
      }));
    }
  };

  return (
    <Box mb={2} sx={{ position: "relative" }}>
      {!poll?.data?.deleted && (
        <Fab
          aria-controls={anchorEl ? "options-menu" : undefined}
          aria-expanded={anchorEl ? "true" : undefined}
          aria-haspopup="true"
          id="options-button"
          size="medium"
          sx={{
            position: "absolute",
            top: "24px",
            right: "24px",
          }}
          variant="ghost"
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          <MoreHoriz />
        </Fab>
      )}

      <Menu
        MenuListProps={{
          "aria-labelledby": "options-button",
        }}
        anchorEl={anchorEl}
        id="options-menu"
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem disabled={isEditing} onClick={handleOpenPollEditor}>
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText>Edit poll</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => setDeletePollDialogOpen(true)}>
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <ListItemText>Delete poll</ListItemText>
        </MenuItem>
      </Menu>

      <DeletePollDialog
        open={deletePollDialogOpen}
        onClose={handleCloseDeletePollDialog}
        onDelete={handleDeletePollData}
      />

      <Poll
        {...poll?.data}
        caption="Only you can see how people vote. A poll cannot be edited after it is posted."
        context="editor"
        options={poll?.data?.options?.map((option) => ({ value: option }))}
      />
    </Box>
  );
}
