import { Button, Typography } from "@mui/material";
import { useRef, useState } from "react";

import DotSeparator from "components/ui/DotSeparator";
import useMyGroups from "hooks/useMyGroups";
import { FeedActivity } from "lib/motorcade/types";

import GroupJoinMenuWrapper from "./GroupJoinMenuWrapper";

type Props = Pick<FeedActivity, "groupRef">;

export default function GroupJoinCTA({ groupRef }: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const joinWrapperRef = useRef<HTMLDivElement>(null);

  const { myGroups } = useMyGroups();
  const isMember = myGroups.some(
    ({ id }: { id: string }) => id === groupRef?.id
  );

  const open = !!anchorEl;

  const handleClick = () => setAnchorEl(joinWrapperRef.current);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <DotSeparator />
      <div ref={joinWrapperRef} style={{ lineHeight: 1 }}>
        {isMember ? (
          <Typography color="textSecondary" variant="caption">
            Joined
          </Typography>
        ) : (
          <Button
            aria-controls={open ? "join-group-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            className="pendo_desktop-feed-card-join-group"
            id="join-group-button"
            variant="link"
            onClick={handleClick}
          >
            Join Group
          </Button>
        )}
      </div>
      <GroupJoinMenuWrapper
        anchorEl={anchorEl}
        handleClose={handleClose}
        id={groupRef.id}
        open={open}
      />
    </>
  );
}
