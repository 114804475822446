import AlternateEmail from "@mui/icons-material/AlternateEmail";
import IconButton from "@mui/material/IconButton";

export default function InsertMentionButton({
  state,
  setState,
  view,
  ...props
}) {
  return (
    <IconButton
      size="large"
      title="Add Mention"
      onClick={() => {
        const { tr } = state;
        tr.insertText("@");
        setState(state.apply(tr));

        if (view) {
          view.focus();
        }
      }}
      {...props}
    >
      <AlternateEmail fontSize="small" />
    </IconButton>
  );
}
