import { Button, FormControl, RadioGroup, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useState } from "react";

import Truncate from "components/shared/Truncate";
import DotSeparator from "components/ui/DotSeparator";
import pluralize from "lib/common/pluralize";
import timeLeft from "lib/date/timeLeft";

import PollDeleted from "./PollDeleted";
import PollOption from "./PollOption";
import PollVotesDialog from "./PollVotesDialog";

type Props = {
  caption: string;
  closed?: boolean;
  condensed?: boolean;
  deleted?: boolean;
  closeAt: string;
  handleRemoveVote?: () => void;
  handleVoteChange?: (value: string) => void;
  options: {
    label?: string;
    value: string;
    votes?: number;
    votePercentage?: number;
  }[];
  question: string;
  selectedVote?: string;
  showRemoveVote?: boolean;
  showVoteDetails?: boolean;
  showVotePercentage?: boolean;
  totalVotes?: number;
  context?: "feed" | "editor";
  pollId: string;
};

export default function Poll({
  caption,
  closed,
  condensed,
  deleted,
  closeAt,
  handleRemoveVote,
  handleVoteChange,
  options,
  question,
  selectedVote,
  showRemoveVote,
  showVoteDetails,
  showVotePercentage,
  totalVotes,
  context = "feed",
  pollId,
}: Props) {
  const [votesDialogOpen, setVotesDialogOpen] = useState(false);

  if (deleted) {
    return <PollDeleted />;
  }

  const openVotesDialog = () => {
    setVotesDialogOpen(true);
  };

  const rawOptions = options?.map((option) => option.value);

  return (
    <>
      <Box
        border="1px solid"
        borderColor="grey.300"
        borderRadius="5px"
        px={2}
        py={condensed ? 1 : 3}
      >
        <Truncate maxLines={condensed && 3}>
          <Typography variant="body1Semibold">{question}</Typography>
        </Truncate>

        {condensed ? (
          <Button fullWidth sx={{ mt: 0.5 }} variant="contained">
            Go to poll
          </Button>
        ) : (
          <>
            {caption && (
              <Typography component="p" sx={{ mt: 1 }} variant="caption">
                {caption}
              </Typography>
            )}

            <FormControl fullWidth sx={{ mt: 2, mb: 3 }}>
              <RadioGroup
                value={selectedVote || null}
                onChange={
                  context === "feed"
                    ? (e) => handleVoteChange(e.target.value)
                    : undefined
                }
              >
                <Stack gap={1}>
                  {options?.map((option) => (
                    <PollOption
                      key={option.value}
                      disabled={closed}
                      selected={selectedVote === option.value}
                      showVotePercentage={showVotePercentage}
                      {...option}
                    />
                  ))}
                </Stack>
              </RadioGroup>
            </FormControl>

            <Box alignItems="center" display="flex" gap={1}>
              {context === "feed" && showVoteDetails && (
                <>
                  {totalVotes > 0 ? (
                    <Button variant="link" onClick={openVotesDialog}>
                      {totalVotes} {pluralize("vote", totalVotes)}
                    </Button>
                  ) : (
                    <Typography variant="body2">
                      {totalVotes} {pluralize("vote", totalVotes)}
                    </Typography>
                  )}

                  <DotSeparator />
                </>
              )}
              <Typography variant="subtitle2">
                {timeLeft({ endDate: closeAt }) || "Poll has ended"}
              </Typography>
              {showRemoveVote && (
                <>
                  <DotSeparator />
                  <Button variant="link" onClick={handleRemoveVote}>
                    Remove answer
                  </Button>
                </>
              )}
            </Box>
          </>
        )}
      </Box>

      <PollVotesDialog
        open={votesDialogOpen}
        options={rawOptions}
        pollId={pollId}
        setOpen={setVotesDialogOpen}
      />
    </>
  );
}
