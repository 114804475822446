import { LoadingButton } from "@mui/lab";
import { Box, useMediaQuery } from "@mui/material";
import { Theme } from "@mui/system";
import { useFlags } from "launchdarkly-react-client-sdk";
import { EditorState } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { Dispatch, SetStateAction } from "react";

import { ActivityEditorData } from "components/feed/ActivityEditor/ActivityEditorDialog";
import CreateEventButton from "components/feed/ActivityEditor/buttons/CreateEventButton";
import CreatePollButton from "components/feed/ActivityEditor/buttons/CreatePollButton";
import InsertMediaButton from "components/feed/ActivityEditor/buttons/InsertMediaButton";
import InsertEmojiButton from "components/shared/inputs/Editor/buttons/InsertEmojiButton";
import InsertLinkButton from "components/shared/inputs/Editor/buttons/InsertLinkButton";
import InsertMentionButton from "components/shared/inputs/Editor/buttons/InsertMentionButton";
import { docHasLink } from "components/shared/inputs/Editor/helpers";
import useIdentity from "hooks/useIdentity";
import useMe from "hooks/useMe";

export type Props = {
  state: EditorState;
  setState: Dispatch<SetStateAction<EditorState>>;
  view?: EditorView;
  hasMediaAttachment: boolean;
  setMediaAttachmentData: Dispatch<SetStateAction<{ file: File }>>;
  setActiveEditorDialog: Dispatch<SetStateAction<"post" | "poll" | "event">>;
  activityEditorData: ActivityEditorData;
  canSubmit: boolean;
  onSubmit: () => void;
  isEditing: boolean;
  setIsDirty: (value: boolean) => void;
  isSubmitting: boolean;
  hasPoll: boolean;
  handleOpenPollEditor: () => void;
};

export default function PostEditorMenu({
  activityEditorData,
  canSubmit,
  handleOpenPollEditor,
  hasMediaAttachment,
  hasPoll,
  isEditing,
  isSubmitting,
  onSubmit,
  setActiveEditorDialog,
  setIsDirty,
  setMediaAttachmentData,
  setState,
  state,
  view,
}: Props) {
  const isSmDown = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { permEvents, permPolls } = useFlags();

  const { role } = useIdentity();
  const { myGroups } = useMe();

  const { group } = activityEditorData;

  const hasLink = docHasLink(state.doc);
  const isGroupILead =
    group && myGroups?.groupsILead?.find((g) => g.id === group?.id);
  const canCreateEvent = (role === "ADMIN" || isGroupILead) && !isEditing;
  const canCreatePoll =
    !isEditing && !hasLink && !hasMediaAttachment && !hasPoll;

  const hideInsertMediaButton = hasMediaAttachment;
  const hideCreateEventButton = !canCreateEvent || !permEvents;
  const hideCreatePollButton = !permPolls;
  const hideEmojiButton = isSmDown;
  const hideSubmitButton = isSmDown;
  const disableLinkButton = hasPoll;
  const disableInsertMediaButton = hasPoll;
  const disableCreatePollButton = !canCreatePoll;

  const handleSetMediaAttachmentFile = (file: File) => {
    setIsDirty(true);
    setMediaAttachmentData({ file });
  };

  let buttonPendoClassName = "pendo_web-post-new-post";
  if (isEditing) {
    if (hasPoll) buttonPendoClassName = "pendo_web-edit-poll-save";
    buttonPendoClassName = "pendo_web-edit-post-save";
  } else if (hasPoll) buttonPendoClassName = "pendo_web-post-new-poll";

  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="space-between"
      sx={(theme) => ({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        border: `1px solid ${theme.palette.primary.light}`,
        borderRadius: theme.spacing(0.5),
        backgroundColor: "transparent",
        padding: "5px 10px",

        [theme.breakpoints.down("sm")]: {
          border: 0,
          borderTop: `1px solid ${theme.palette.grey["300"]}`,
          borderRadius: 0,
          margin: "0 -16px",
        },
      })}
    >
      <Box alignItems="center" display="flex" flexDirection="row">
        <InsertLinkButton
          className="pendo_web-post-add-link"
          disabled={disableLinkButton}
          setState={setState}
          state={state}
          view={view}
        />

        {!hideInsertMediaButton && (
          <InsertMediaButton
            className="pendo_web-post-add-image"
            disabled={disableInsertMediaButton}
            setMediaAttachmentFile={handleSetMediaAttachmentFile}
          />
        )}

        <InsertMentionButton
          className="pendo_web-post-add-mention"
          setState={setState}
          state={state}
          view={view}
        />

        {!hideEmojiButton && (
          <InsertEmojiButton
            className="pendo_web-post-add-emoji"
            setState={setState}
            state={state}
            view={view}
          />
        )}

        {!hideCreateEventButton && (
          <CreateEventButton onClick={() => setActiveEditorDialog("event")} />
        )}

        {!hideCreatePollButton && (
          <CreatePollButton
            className="pendo_web-post-add-poll"
            disabled={disableCreatePollButton}
            onClick={handleOpenPollEditor}
          />
        )}
      </Box>

      {!hideSubmitButton && (
        <LoadingButton
          className={buttonPendoClassName}
          disabled={!canSubmit || isSubmitting}
          loading={isSubmitting}
          sx={{ px: 2, py: 1 }}
          variant="contained"
          onClick={onSubmit}
        >
          {isEditing ? "Save" : "Post"}
        </LoadingButton>
      )}
    </Box>
  );
}
