import { Button } from "@mui/material";
import Card from "@mui/material/Card";

import ActivityCardFooter from "components/feed/FeedCard/base/ActivityCardFooter";
import ActivityCardHeader from "components/feed/FeedCard/base/ActivityCardHeader";
import EditorsChoiceCard from "components/feed/FeedCard/cards/EditorsChoiceCard/index";
import MediaContent from "components/feed/FeedCard/cards/MediaCard/MediaContent";
import { FeedActivity, UrlMetadata } from "lib/motorcade/types";

type Props = {
  activity: FeedActivity & {
    verb: "article";
  };
  isPinned?: boolean;
  loading: boolean;
  urlMetadata: UrlMetadata;
};

export default function ArticleCard({
  activity,
  isPinned,
  loading,
  urlMetadata,
}: Props) {
  const isEditorsChoice = activity?.object?.data?.editorsChoice;

  if (isEditorsChoice) {
    return (
      <EditorsChoiceCard
        activity={activity}
        loading={loading}
        urlMetadata={urlMetadata}
      />
    );
  }

  return (
    <Card
      className={isPinned ? "pendo_groups-pinned-article" : "pendo_web-article"}
    >
      <ActivityCardHeader
        actionText="published an article"
        activity={activity}
        date={activity?.object?.updated_at}
      />
      <MediaContent
        cta={
          <Button
            className="pendo_web-feed-card-read-article"
            href={urlMetadata?.url}
            target="_blank"
            variant="contained"
          >
            Read article
          </Button>
        }
        loading={loading}
        urlMetadata={urlMetadata}
      />
      <ActivityCardFooter activity={activity} />
    </Card>
  );
}
