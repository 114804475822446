import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, styled } from "@mui/material";
import Image from "next/legacy/image";
import { useEffect, useState } from "react";

export type MediaAttachmentData = {
  file?: File;
  url?: string;
  type?: string;
};

type Props = {
  attachmentData?: MediaAttachmentData;
  onDelete: () => void;
};

async function readFile(file: File): Promise<string> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => resolve(e.target.result as string);
    reader.readAsDataURL(file);
  });
}

// Styled components
const Wrapper = styled(Box)(({ theme }) => ({
  height: "80px",
  width: "80px",
  position: "relative",
  margin: theme.spacing(0, 0, 1, 1),
}));

const StyledImage = styled(Image)({
  borderRadius: "4px",
});

const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
  fontSize: "15px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "26px",
  },
}));

const RemoveButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: "4px",
  top: "4px",
  transform: "translate(50%, -50%)",
  height: "20px",
  width: "20px",
  fontSize: "16px",
  background: theme.palette.primary.main,
  border: "2px solid white",
  color: "white",
  "&:hover": {
    background: theme.palette.primary.main,
  },
  [theme.breakpoints.down("sm")]: {
    height: "34px",
    width: "34px",
    border: "3px solid white",
    right: "9px",
    top: "9px",
  },
}));

export default function MediaAttachmentPreview({
  attachmentData,
  onDelete,
}: Props) {
  const [data, setData] = useState<string | null>();

  const fileType = attachmentData?.file?.type || attachmentData?.type;

  useEffect(() => {
    (async () => {
      if (attachmentData?.url) {
        setData(attachmentData?.url);
      }

      if (attachmentData?.file) {
        const fileContents = await readFile(attachmentData?.file);
        setData(fileContents);
      }
    })();
  }, [attachmentData]);

  if (!attachmentData || !data) {
    return null;
  }

  return (
    <Wrapper>
      {fileType?.includes("image") && (
        <StyledImage layout="fill" objectFit="cover" src={data} />
      )}

      {fileType?.includes("video") && (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video height="80" width="80">
          <source src={data} type={fileType} />
        </video>
      )}

      <RemoveButton size="small" onClick={onDelete}>
        <StyledCloseIcon />
      </RemoveButton>
    </Wrapper>
  );
}
