import ArticleCard from "components/feed/FeedCard/cards/ArticleCard";
import CommunityEPRoundupCard from "components/feed/FeedCard/cards/CommunityEPRoundupCard";
import GroupEPRoundupCard from "components/feed/FeedCard/cards/GroupEPRoundupCard";
import useMetadata from "lib/motorcade/hooks/useMetadata";
import {
  ArticleCollection,
  ExpertPanelRoundupCollection,
  FeedActivity,
  StreamUser,
} from "lib/motorcade/types";

type Props = {
  activity: FeedActivity;
  actors?: StreamUser[];
};

export default function MediaCard({ activity, actors }: Props) {
  const { verb } = activity;
  const object = activity?.object as
    | ArticleCollection
    | ExpertPanelRoundupCollection;
  const { loading, data: urlMetadata } = useMetadata(object);

  switch (verb) {
    case "article":
      return (
        <ArticleCard
          activity={activity}
          loading={loading}
          urlMetadata={urlMetadata}
        />
      );

    case "expertpanel":
      return activity?.groupRef ? (
        <GroupEPRoundupCard
          activity={activity}
          loading={loading}
          urlMetadata={urlMetadata}
        />
      ) : (
        <CommunityEPRoundupCard
          activity={activity}
          actors={actors}
          loading={loading}
          urlMetadata={urlMetadata}
        />
      );

    default:
      return null;
  }
}
