import { useQuery } from "@apollo/client";

import { FEED_POLL_VOTES } from "lib/motorcade/graphql/queries";
import { GraphUser } from "lib/motorcade/types";

import OptionAccordion from "./OptionAccordion";

type Props = {
  pollId: string;
  option: string;
};

export default function PollOptionAccordion({ pollId, option }: Props) {
  const { data, loading } = useQuery(FEED_POLL_VOTES, {
    variables: {
      input: {
        pollId,
        option,
      },
    },
    fetchPolicy: "no-cache",
  });

  const totalVotes = data?.feedPollVotes?.numberOfVotes || 0;
  const users: GraphUser[] = data?.feedPollVotes?.voters?.edges || [];

  return (
    <OptionAccordion
      loading={loading}
      option={option}
      totalVotes={totalVotes}
      users={users}
    />
  );
}
