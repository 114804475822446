import { Button, Divider } from "@mui/material";
import { Stack } from "@mui/system";

import ResponseSkeleton from "components/ui/ResponseSkeleton";
import useActivity from "lib/motorcade/hooks/useActivity";
import { CommentReaction } from "lib/motorcade/types";

import ActivityCardComment from "./ActivityCardComment";

export default function ActivityCardComments({ activity }) {
  const { loadMoreComments, loadingMoreComments } = useActivity({ activity });

  const comments = activity?.latest_reactions?.comment?.sort(
    (a, b) =>
      new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
  );

  if (!comments) {
    return null;
  }

  return (
    <>
      <Divider />

      <Stack spacing={1}>
        {activity?.reaction_counts?.comment > comments.length && (
          <Button
            className="pendo_web-post-view-previous-comments"
            sx={{
              alignSelf: "flex-start",
              color: (theme) => theme.palette.text.secondary,
            }}
            variant="link"
            onClick={() => loadMoreComments()}
          >
            View previous comments
          </Button>
        )}
        {loadingMoreComments && (
          <>
            <ResponseSkeleton />
            <ResponseSkeleton />
          </>
        )}
        {comments?.map((comment: CommentReaction) => (
          <ActivityCardComment
            key={comment.id}
            activity={activity}
            comment={comment}
          />
        ))}
      </Stack>
    </>
  );
}
