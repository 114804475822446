import { Box } from "@mui/material";

export default function DotSeparator() {
  return (
    <Box
      height="3px"
      sx={{ backgroundColor: "text.secondary", borderRadius: "100%" }}
      width="3px"
    />
  );
}
