import { useMutation as useApolloMutation } from "@apollo/client";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { FeedAPIResponse } from "getstream";

import { useRouter } from "next/router";
import { useMemo } from "react";

import useGroupFull from "hooks/useGroupFull";
import { GROUP_PINNED_FEED_GROUP } from "lib/motorcade/constants";
import {
  FEED_GROUP_PIN,
  FEED_GROUP_UNPIN,
} from "lib/motorcade/graphql/mutations";
import { AlertCallback, FeedActivity } from "lib/motorcade/types";
import { addPinActivity, removePinActivity } from "lib/motorcade/utils/state";

type PinMutationInput = {
  id: string;
  postType?: "POST" | "POLL";
};

type UsePinActivityProps = {
  activity: FeedActivity;
  alertCallback: AlertCallback;
};

export default function usePinActivity({
  activity,
  alertCallback,
}: UsePinActivityProps) {
  const queryClient = useQueryClient();
  const groupId = activity?.groupRef?.id;

  const router = useRouter();
  const { slug } = router.query;
  const { group } = useGroupFull({
    slug: Array.isArray(slug) ? slug[0] : slug,
  });

  const pinFeedQueryKey = useMemo(() => {
    if (group) {
      return `${GROUP_PINNED_FEED_GROUP}:${group.restrictedId ?? group.id}`;
    }
    return `${GROUP_PINNED_FEED_GROUP}:${groupId}`;
  }, [group, groupId]);

  const [pin] = useApolloMutation(FEED_GROUP_PIN);
  const [unpin] = useApolloMutation(FEED_GROUP_UNPIN);

  const { mutate: pinActivity } = useMutation({
    mutationFn: async (input: PinMutationInput) => {
      await pin({ variables: { input } });
      queryClient.invalidateQueries([pinFeedQueryKey]);
    },
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: [pinFeedQueryKey] });
      const prevFeed = queryClient.getQueryData([pinFeedQueryKey]);

      queryClient.setQueryData([pinFeedQueryKey], (prevData) => {
        const updatedData = addPinActivity({
          feedData: prevData,
          activity,
        });
        return updatedData;
      });

      return { prevFeed };
    },
    onSuccess: () => {
      alertCallback({
        message: "Post has been successfully pinned.",
        title: "Congratulations!",
        type: "success",
      });
    },
    onError: (err, variables, context) => {
      const tooManyPinsErrorMessage =
        "Maximum of 5 Posts can be pinned. Please unpin a Post and try again.";

      if (err instanceof Error && err.message === tooManyPinsErrorMessage) {
        alertCallback({
          message: tooManyPinsErrorMessage,
          type: "error",
        });
      } else {
        alertCallback({
          type: "error",
          message: "An error has occurred.  Please try again.",
        });
      }
      queryClient.setQueryData([pinFeedQueryKey], context.prevFeed);
    },
  });

  const { mutate: unpinActivity } = useMutation({
    mutationFn: async (input: PinMutationInput) => {
      await unpin({ variables: { input } });
      queryClient.invalidateQueries([pinFeedQueryKey]);
    },
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: [pinFeedQueryKey] });
      const prevFeed = queryClient.getQueryData([pinFeedQueryKey]);

      queryClient.setQueryData(
        [pinFeedQueryKey],
        (prevData: FeedAPIResponse) => {
          return removePinActivity({
            feedData: prevData,
            activity,
          });
        }
      );

      return { prevFeed };
    },
    onSuccess: () => {
      alertCallback({
        message: "Post has been successfully unpinned.",
        title: "Congratulations!",
        type: "success",
      });
    },
    onError: (err, variables, context) => {
      alertCallback({
        type: "error",
        message: "An error has occurred.  Please try again.",
      });
      queryClient.setQueryData([pinFeedQueryKey], context.prevFeed);
    },
  });

  return {
    pinActivity,
    unpinActivity,
  };
}
