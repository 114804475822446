import { gql } from "@apollo/client";

export const FEED_POLL_VOTES = gql`
  query feedPollVotes($input: FeedPollVotesInput!) {
    feedPollVotes(input: $input) {
      numberOfVotes
      voters {
        edges {
          node {
            id
            snapshot {
              name
              avatar
              companyName
            }
          }
        }
      }
    }
  }
`;
