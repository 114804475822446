/* eslint-disable camelcase */
import { useMutation as useApolloMutation } from "@apollo/client";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FeedAPIResponse } from "getstream";
import { cloneDeep } from "lodash";
import { useContext } from "react";

import { MediaAttachmentData } from "components/ui/MediaAttachmentPreview";
import useMe from "hooks/useMe";
import { useApp } from "lib/common/appProvider";
import parseMentions from "lib/markdown/parseMentions";
import { NOTIFICATION_FEED_GROUP } from "lib/motorcade/constants";
import {
  FEED_COMMENT_CREATE,
  FEED_COMMENT_UPDATE,
} from "lib/motorcade/graphql/mutations";
import { StreamContext } from "lib/motorcade/providers/stream";
import {
  AlertCallback,
  CcoReaction,
  CommentReaction,
  FeedActivity,
  ReplyReaction,
} from "lib/motorcade/types";

import { findMyReaction } from "lib/motorcade/utils/finders";
import {
  removePendingReactions,
  removePendingResponses,
  updateFeedActivity,
} from "lib/motorcade/utils/state";
import { getReactionStub, getResponseStub } from "lib/motorcade/utils/stubs";

import { FeedCommentUpdateInput } from "./useComment";

import { useFileUploader } from "./useFileUploader";

type Props = {
  activity: FeedActivity;
  comment: CommentReaction;
  reply?: ReplyReaction;
  alertCallback: AlertCallback;
};

export type ReplyInput = {
  markdown: string;
  mediaFile?: File | string;
  mediaType?: string;
};

export default function useReply({
  activity,
  comment,
  reply,
  alertCallback,
}: Props) {
  const { streamClient, currentFeedQueryKey } = useContext(StreamContext);
  const queryClient = useQueryClient();
  const me = useMe();
  const myId = me.id;
  const { uploadFile } = useFileUploader();
  const site = useApp("site");
  const conciergeEmail = site?.concierge?.email;

  const [createReply] = useApolloMutation(FEED_COMMENT_CREATE);
  const [feedCommentUpdate] = useApolloMutation(FEED_COMMENT_UPDATE);

  const { mutateAsync: addReply, isLoading: addReplyLoading } = useMutation({
    mutationFn: async (input: ReplyInput) => {
      let attachment: MediaAttachmentData | undefined;
      if (input?.mediaFile instanceof File && input?.mediaFile) {
        attachment = await uploadFile(input.mediaFile);
      }

      const { data } = await createReply({
        variables: {
          input: {
            activityId: activity.id,
            parentCommentId: comment.id,
            markdown: input.markdown,
            mentionedUserIds: parseMentions(input.markdown),
            postType: activity.type === "poll" ? "POLL" : "POST",
            attachment,
          },
        },
      });

      const newReply = await streamClient.reactions.get(
        data.feedCommentCreate.id
      );

      return { newReply };
    },
    onMutate: async (input: ReplyInput) => {
      await queryClient.cancelQueries({ queryKey: [currentFeedQueryKey] });
      const prevFeed = queryClient.getQueryData([currentFeedQueryKey]);

      const pendingReply = getResponseStub({
        author: me,
        input,
        type: "reply",
      });

      const updatedComment = {
        ...comment,
        latest_children: {
          ...comment.latest_children,
          reply: [pendingReply, ...(comment.latest_children.reply || [])],
        },
        children_counts: {
          ...comment.children_counts,
          reply: (comment.children_counts.reply || 0) + 1,
        },
      };

      const newData = {
        latest_reactions: {
          ...activity.latest_reactions,
          comment: activity.latest_reactions.comment.map((c) =>
            c.id === comment.id ? updatedComment : c
          ),
        },
        own_reactions: {
          ...activity.own_reactions,
          comment: activity?.own_reactions?.comment?.map((c) =>
            c.id === comment.id ? updatedComment : c
          ),
        },
      };

      queryClient.setQueryData([currentFeedQueryKey], (feed: FeedAPIResponse) =>
        updateFeedActivity({
          feed,
          activity,
          newData,
          queryKey: currentFeedQueryKey,
        })
      );

      return { prevFeed };
    },
    onSuccess: ({ newReply }) => {
      const pendingRemovedComment = removePendingResponses({
        activity: comment,
        type: "reply",
      });

      pendingRemovedComment.latest_children.reply.push(newReply);

      const newData = {
        ...activity,
        latest_reactions: {
          ...activity.latest_reactions,
          comment: activity.latest_reactions.comment.map((c) =>
            c.id === comment.id ? pendingRemovedComment : c
          ),
        },
      };

      queryClient.setQueryData([currentFeedQueryKey], (feed: FeedAPIResponse) =>
        updateFeedActivity({
          activity,
          feed,
          newData,
          queryKey: currentFeedQueryKey,
        })
      );

      alertCallback({
        message: "Reply successfully added.",
        type: "success",
      });
    },
    onError: (err, _variables, context) => {
      queryClient.setQueryData([currentFeedQueryKey], context.prevFeed);

      if (
        err instanceof Error &&
        err.message === "Request denied. Action is not permitted for User."
      ) {
        alertCallback({
          message: `We have received several reports of spam posts associated with your account, leading to a temporary access restriction. Please reach out to ${conciergeEmail} so our team can reshare our community guidelines and assist you with restoring access.`,
          type: "error",
        });
      } else {
        alertCallback({
          message: "Failed to add reply. Try again.",
          type: "error",
        });
      }
    },
  });

  const { mutateAsync: updateReply, isLoading: updateReplyLoading } =
    useMutation({
      mutationFn: async (input: ReplyInput) => {
        let attachment: FeedCommentUpdateInput["attachment"] | undefined;
        if (input?.mediaFile) {
          if (input?.mediaFile instanceof File) {
            attachment = await uploadFile(input.mediaFile);
          } else {
            attachment = {
              url: input.mediaFile,
              mediaType: input.mediaType,
            };
          }
        }

        await feedCommentUpdate({
          variables: {
            input: {
              commentId: reply.id,
              markdown: input.markdown,
              attachment,
              mentionedUserIds: parseMentions(input.markdown),
            },
          },
        });
      },
      onMutate: async (input: ReplyInput) => {
        await queryClient.cancelQueries({ queryKey: [currentFeedQueryKey] });
        const prevFeed = queryClient.getQueryData([currentFeedQueryKey]);

        const updatedReply = {
          ...reply,
          data: {
            ...reply.data,
            body: input.markdown,
            mediaUrl: input.mediaFile,
            mediaType: input.mediaType,
          },
          updated_at: new Date().toISOString(),
        };

        const updatedComment = {
          ...comment,
          latest_children: {
            ...comment.latest_children,
            reply: comment.latest_children.reply.map((r) =>
              r.id === reply.id ? updatedReply : r
            ),
          },
          children_counts: {
            ...comment.children_counts,
          },
        };

        const newData = {
          latest_reactions: {
            ...activity.latest_reactions,
            comment: activity.latest_reactions.comment.map((c) =>
              c.id === comment.id ? updatedComment : c
            ),
          },
          own_reactions: {
            ...activity.own_reactions,
            comment: activity.own_reactions.comment?.map((c) =>
              c.id === comment.id ? updatedComment : c
            ),
          },
        };

        queryClient.setQueryData(
          [currentFeedQueryKey],
          (feed: FeedAPIResponse) =>
            updateFeedActivity({
              feed,
              activity,
              newData,
              queryKey: currentFeedQueryKey,
            })
        );

        return { prevFeed };
      },
      onSuccess: () => {
        alertCallback({
          message: "Reply successfully updated.",
          type: "success",
        });
      },
      onError: (err, variables, context) => {
        queryClient.setQueryData([currentFeedQueryKey], context.prevFeed);
        alertCallback({
          message: "Failed to update reply. Try again.",
          type: "error",
        });
      },
    });

  const { mutate: addReaction } = useMutation({
    mutationFn: async (kind: CcoReaction) => {
      await queryClient.cancelQueries({ queryKey: [currentFeedQueryKey] });

      // @todo refactor out cloneDeep
      const updatedActivity = cloneDeep(activity);
      const updatedComment = cloneDeep(comment);
      const updatedReply = cloneDeep(reply);
      const { latest_children, children_counts } = updatedReply;

      const myReaction = findMyReaction({
        activity: updatedReply,
        myId,
      });

      // If myReaction exists, delete it from latest_children
      if (myReaction) {
        latest_children[myReaction.kind] = latest_children[
          myReaction.kind
        ].filter((reaction) => reaction.id !== myReaction.id);
      }

      // Add my new reaction to latest_children
      const pendingReaction = getReactionStub({
        author: me,
        kind,
      });
      latest_children[kind] = [
        ...(latest_children[kind] || []),
        pendingReaction,
      ];

      // If my reaction exists remove my reaction from children_counts
      if (myReaction) children_counts[myReaction.kind] -= 1;

      //  Add my new reaction to children_counts
      children_counts[kind] = children_counts[kind]
        ? children_counts[kind] + 1
        : 1;

      // Find the reply in updatedComment and replace it with updatedReply
      const replyIndex = updatedComment.latest_children.reply.findIndex(
        (updatedCommentReply) => updatedCommentReply.id === updatedReply.id
      );
      updatedComment.latest_children.reply[replyIndex] = updatedReply;

      // Find the comment in updatedActivity and replace it with updatedComment
      const commentIndex = updatedActivity.latest_reactions.comment.findIndex(
        (updatedActivityComment) =>
          updatedActivityComment.id === updatedComment.id
      );
      updatedActivity.latest_reactions.comment[commentIndex] = updatedComment;

      queryClient.setQueryData([currentFeedQueryKey], (feed: unknown) => {
        return updateFeedActivity({
          activity,
          feed,
          newData: updatedActivity,
          queryKey: currentFeedQueryKey,
        });
      });

      if (myReaction) await streamClient?.reactions?.delete(myReaction.id);

      const newReaction = await streamClient.reactions.addChild(
        kind,
        reply,
        {
          type: kind,
          activityType: "reply",
          redirectUrl: `/activities/${activity.id}`,
        },
        {
          targetFeeds:
            updatedReply.user_id !== myId
              ? [`${NOTIFICATION_FEED_GROUP}:${updatedReply.user_id}`]
              : [],
          targetFeedsExtraData: {
            parentId: updatedReply.id,
          },
        }
      );
      return { newReaction };
    },
    onSuccess: ({ newReaction }) => {
      queryClient.setQueryData([currentFeedQueryKey], (feed: unknown) => {
        const pendingRemovedReply = removePendingReactions(reply);
        pendingRemovedReply.latest_children[newReaction.kind].push(newReaction);

        const replyIndex = comment.latest_children.reply.findIndex(
          (updatedCommentReply) =>
            updatedCommentReply.id === pendingRemovedReply.id
        );
        comment.latest_children.reply[replyIndex] = pendingRemovedReply;

        const commentIndex = activity.latest_reactions.comment.findIndex(
          (activityComment) => activityComment.id === comment.id
        );
        activity.latest_reactions.comment[commentIndex] = comment;

        return updateFeedActivity({
          activity,
          feed,
          newData: activity,
          queryKey: currentFeedQueryKey,
        });
      });
    },

    onError: () => {
      // @todo add error handling
    },
  });

  const { mutate: removeReaction } = useMutation({
    mutationFn: async () => {
      await queryClient.cancelQueries({ queryKey: [currentFeedQueryKey] });

      // @todo refactor out cloneDeep
      const updatedActivity = cloneDeep(activity);
      const updatedComment = cloneDeep(comment);
      const updatedReply = cloneDeep(reply);
      const { latest_children, children_counts } = updatedReply;

      const myReaction = findMyReaction({
        activity: updatedReply,
        myId,
      });

      if (myReaction) {
        // If myReaction exists, delete it from latest_children
        latest_children[myReaction.kind] = latest_children[
          myReaction.kind
        ].filter((reaction) => reaction.id !== myReaction.id);

        // If my reaction exists remove my reaction from children_counts
        children_counts[myReaction.kind] -= 1;

        // Find the reply in updatedComment and replace it with updatedReply
        const replyIndex = updatedComment.latest_children.reply.findIndex(
          (updatedCommentReply) => updatedCommentReply.id === updatedReply.id
        );
        updatedComment.latest_children.reply[replyIndex] = updatedReply;

        // Find the comment in updatedActivity and replace it with updatedComment
        const commentIndex = updatedActivity.latest_reactions.comment.findIndex(
          (updatedActivityComment) =>
            updatedActivityComment.id === updatedComment.id
        );
        updatedActivity.latest_reactions.comment[commentIndex] = updatedComment;

        queryClient.setQueryData([currentFeedQueryKey], (feed: unknown) => {
          return updateFeedActivity({
            activity,
            feed,
            newData: updatedActivity,
            queryKey: currentFeedQueryKey,
          });
        });

        await streamClient?.reactions?.delete(myReaction.id);
      }
    },
    onError: () => {
      // @todo add error handling
    },
  });

  return {
    addReply,
    addReplyLoading,
    updateReply,
    updateReplyLoading,
    addReaction,
    removeReaction,
  };
}
