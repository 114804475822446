import { FormControlLabel, Radio, Typography } from "@mui/material";
import { Box } from "@mui/system";

import TaskAlt from "components/icons/TaskAlt";

import PollOptionLabel from "./PollOptionLabel";

type Props = {
  disabled?: boolean;
  selected?: boolean;
  showVotePercentage?: boolean;
  value: string;
  votePercentage?: number;
};

export default function PollOption({
  disabled,
  selected,
  showVotePercentage,
  value,
  votePercentage = 0,
}: Props) {
  return (
    <Box
      border="1px solid"
      borderColor="grey.300"
      borderRadius="8px"
      height={60}
      overflow="hidden"
      position="relative"
    >
      <Box
        height="100%"
        position="absolute"
        sx={{
          transition: "width 0.25s ease-in-out",
          backgroundColor: (theme) => theme.palette.grey[300],
        }}
        width={showVotePercentage ? `${votePercentage}%` : 0}
      />
      <Box
        alignItems="center"
        display="flex"
        height="100%"
        justifyContent="space-between"
        p={1}
      >
        <Box alignItems="center" display="flex" gap={1} zIndex={1}>
          {disabled ? (
            <PollOptionLabel value={value} />
          ) : (
            <FormControlLabel
              control={<Radio />}
              label={<PollOptionLabel value={value} />}
              value={value}
            />
          )}
          {selected && <TaskAlt style={{ fontSize: "20px" }} />}
        </Box>
        {showVotePercentage && (
          <Typography sx={{ zIndex: 1 }} variant="body2">
            {votePercentage}%
          </Typography>
        )}
      </Box>
    </Box>
  );
}
