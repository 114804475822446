import {
  ArrowDropDown,
  Groups,
  Lock,
  People,
  Public,
} from "@mui/icons-material";
import { Chip } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Dispatch, SetStateAction, useState } from "react";

import { ActivityEditorData } from "components/feed/ActivityEditor/ActivityEditorDialog";
import AudienceDialog from "components/feed/ActivityEditor/Audience/AudienceDialog";

type Props = {
  disabled: boolean;
  activityEditorData: ActivityEditorData;
  setActivityEditorData: Dispatch<SetStateAction<ActivityEditorData>>;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  excludeOptions?: string[];
};

export const audienceOptions = {
  general: {
    audience: "GENERAL",
    label: "Everyone",
    description: "Posts appear on the Home feed",
    icon: <Public color="primary" />,
  },
  group: {
    audience: "GROUP",
    label: "Everyone + Public Group",
    description: "Posts appear on the Home feed and on the selected Group feed",
    icon: <Groups color="primary" />,
  },
  private_group: {
    audience: "GROUP",
    label: "Private Group",
    description: "Posts appear only for members of a Private Group",
    icon: <Lock color="primary" />,
  },
  profile: {
    audience: "PROFILE",
    label: "My followers",
    description: "Posts appear on the Home feed only for members who follow me",
    icon: <People color="primary" />,
  },
};

export default function Audience({
  disabled,
  activityEditorData,
  setActivityEditorData,
  setIsDirty,
  excludeOptions = [],
}: Props) {
  const { audience, group } = activityEditorData;
  const [audienceDialogOpen, setAudienceDialogOpen] = useState(false);
  const { permGroups, permPrivateGroups } = useFlags();

  if (!permGroups) excludeOptions.push("group");
  if (!permPrivateGroups) excludeOptions.push("private_group");

  let { label, icon } = audienceOptions?.[audience?.toLowerCase()] || {};
  if (group) {
    label = group?.name;
    icon =
      group?.type === "PRIVATE"
        ? audienceOptions.private_group.icon
        : audienceOptions.group.icon;
  }

  return (
    <>
      <Chip
        color="primary"
        deleteIcon={<ArrowDropDown />}
        icon={icon}
        label={label}
        variant="outlined"
        onClick={disabled ? undefined : () => setAudienceDialogOpen(true)}
        onDelete={disabled ? undefined : () => setAudienceDialogOpen(true)}
      />

      <AudienceDialog
        activityEditorData={activityEditorData}
        excludeOptions={excludeOptions}
        open={audienceDialogOpen}
        setActivityEditorData={setActivityEditorData}
        setIsDirty={setIsDirty}
        onClose={() => setAudienceDialogOpen(false)}
      />
    </>
  );
}
