import { useQuery } from "@tanstack/react-query";
import { test } from "linkifyjs";

import metadataFetcher from "lib/common/metadataFetcher";

import {
  ArticleCollection,
  ExpertPanelRoundupCollection,
  UrlMetadata,
} from "lib/motorcade/types/";

export default function useMetadata({
  data: { url, id },
}: ArticleCollection | ExpertPanelRoundupCollection) {
  const isValidUrl = url ? test(url) : false;

  const handleError = () => {
    // eslint-disable-next-line no-console
    console.warn(
      `A feed item of type "Article" and id "${id}" was hidden due to an error retrieving the url metadata`
    );
  };

  const { data, error, isLoading } = useQuery(
    ["metadata", url],
    () => metadataFetcher(url),
    {
      enabled: isValidUrl,
      retry: 1,
      staleTime: 3600 * 1000,
      onError: handleError,
      refetchOnWindowFocus: false,
    }
  );

  return {
    loading: isLoading,
    data: data as UrlMetadata,
    error,
  };
}
