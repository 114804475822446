import {
  Box,
  Button,
  Divider,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Link from "next/link";

import Avatar from "components/shared/Avatar";
import useMe from "hooks/useMe";
import { FeedActivity, StreamUser } from "lib/motorcade/types";

import ActivityCardHeader from "../base/ActivityCardHeader";

type Props = {
  activity: FeedActivity & {
    verb: "follow";
  };
  actors?: StreamUser[];
};

export default function FollowCard({ activity, actors }: Props) {
  const { id: meId } = useMe();
  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.only("xs"));

  if (!activity?.object?.data) {
    return null;
  }

  const {
    id,
    data: { avatar, company, name, title },
  } = activity.object || {};

  const filteredActors = actors?.filter((actor) => actor.id !== meId);

  return (
    <Card className="pendo_desktop-post-activity-follow-member">
      <ActivityCardHeader
        hideAvatar
        hideContextMenu
        hideIsEdited
        actionText="follows"
        activity={activity}
        actors={filteredActors}
      />

      <Box px={3}>
        <Divider />
      </Box>

      <CardContent sx={{ px: 3 }}>
        <Box
          alignItems={isXs ? "center" : "flex-start"}
          display="flex"
          flexDirection={isXs ? "column" : "row"}
          gap={2}
          justifyContent="space-between"
        >
          <Box
            alignItems={isXs ? "center" : "normal"}
            display="flex"
            flexDirection={isXs ? "column" : "row"}
            gap={2}
          >
            <Link href={`/profile/${id}`}>
              <Avatar
                alt={name}
                height={150}
                src={avatar}
                sx={{
                  width: isXs ? "150px" : "90px",
                  height: isXs ? "150px" : "90px",
                }}
                width={150}
              />
            </Link>

            <Box
              display="flex"
              flexDirection="column"
              gap={1}
              textAlign={isXs ? "center" : "start"}
            >
              <Link href={`/profile/${id}`}>
                <Typography color="textPrimary" variant="h3">
                  {name}
                </Typography>
              </Link>

              <div>
                <Typography variant="subtitle2">{title}</Typography>

                <Typography variant="subtitle2">at {company}</Typography>
              </div>
            </Box>
          </Box>

          <Link legacyBehavior passHref href={`/profile/${id}`}>
            <Button sx={{ flexShrink: 0 }} variant="contained">
              View profile
            </Button>
          </Link>
        </Box>
      </CardContent>
    </Card>
  );
}
