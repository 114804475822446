import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

function DatePickerTextField({ error, helperText, ...params }) {
  return (
    <TextField
      error={error}
      helperText={error && helperText}
      inputProps={{
        placeholder: "Enter a start date",
      }}
      name="startDate"
      size="small"
      {...params}
    />
  );
}

export default function DatePicker({
  error = false,
  helperText = "",
  textFieldProps = {},
  ...params
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiDatePicker
        {...params}
        slotProps={{
          textField: {
            error,
            helperText,
            ...textFieldProps,
          },
          ...params.slotProps,
        }}
        slots={{
          textField: DatePickerTextField,
          ...params.slots,
        }}
      />
    </LocalizationProvider>
  );
}
