import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { zonedTimeToUtc } from "date-fns-tz";
import { useCallback } from "react";

import ActivityCardHeader from "components/feed/FeedCard/base/ActivityCardHeader";
import EventCardDate from "components/feed/FeedCard/cards/EventCard/EventCardDate";
import Image from "components/shared/Image";
import Truncate from "components/shared/Truncate";
import useIdentity from "hooks/useIdentity";
import { useApp } from "lib/common/appProvider";
import { FeedActivity } from "lib/motorcade/types";
import trackEvent from "lib/segment/trackEvent";

function getCardPendoClass(isPastEvent, audience, isPinned) {
  if (isPinned) return "pendo_groups-pinned-event";

  if (isPastEvent) {
    return audience === "group"
      ? "pendo_web-groupevent-past"
      : "pendo_web-event-past";
  }

  return audience === "group"
    ? "pendo_web-groupevent-upcoming"
    : "pendo_web-event-upcoming";
}

function getCTAPendoClass(isPastEvent, audience) {
  if (isPastEvent) {
    return audience === "group"
      ? "pendo_web-groupevent-past-CTA"
      : "pendo_web-event-past-CTA";
  }

  return audience === "group"
    ? "pendo_web-groupevent-upcoming-CTA"
    : "pendo_web-event-upcoming-CTA";
}

export const getEventDatesInUtc = (
  startDate: string,
  startTime = "00:00:00",
  endTime: string,
  timezone: string
) => {
  const toUtc = (time: string) =>
    zonedTimeToUtc(`${startDate}T${time}`, timezone);
  return {
    startDateTimeInUtc: toUtc(startTime),
    endDateTimeInUtc: endTime ? toUtc(endTime) : null,
  };
};

type Props = {
  activity: FeedActivity & {
    verb: "event";
  };
  isPinned: boolean;
};

export default function EventCard({ activity, isPinned }: Props) {
  const { communityCode, role, userId } = useIdentity();
  const { user } = useApp("auth");

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { id, mediaUrl, host } = activity;
  const actionText = host?.data?.name
    ? "created an Event for"
    : "An Event was created for";

  const {
    data: {
      audience,
      title,
      cta,
      description,
      endTime,
      link,
      startTime,
      startDate,
      timezone,
      type,
      resourceCta,
      resourceLink,
    },
  } = activity.object;
  const { startDateTimeInUtc, endDateTimeInUtc } = getEventDatesInUtc(
    startDate,
    startTime,
    endTime,
    timezone
  );

  const isPastEvent = startDateTimeInUtc <= new Date();
  const finalCtaText = isPastEvent && resourceCta ? resourceCta : cta;
  const finalCtaLink = isPastEvent && resourceLink ? resourceLink : link;

  const cardPendoClass = getCardPendoClass(isPastEvent, audience, isPinned);
  const ctaPendoClass = getCTAPendoClass(isPastEvent, audience);

  const handleCtaClick = useCallback(
    (urlLink) => {
      trackEvent({
        category: "Events",
        label: title,
        name: "EventClick",
        traits: {
          community_code: communityCode,
          email: user?.email,
          role,
          userId,
          postId: id,
        },
      });
      window.open(urlLink, "_blank");
    },
    [communityCode, id, role, title, user?.email, userId]
  );

  return (
    <Card className={cardPendoClass}>
      <ActivityCardHeader
        actionText={actionText}
        activity={activity}
        actors={host && [host]}
        hideAuthor={!host}
      />
      <Box px={3}>
        <Divider />
      </Box>

      {isMobile && mediaUrl && (
        <Image
          alt={mediaUrl}
          height="210px"
          objectFit="cover"
          src={mediaUrl}
          style={{
            width: "100%",
            height: "auto",
            maxHeight: "210px",
            borderRadius: "0px",
          }}
          width="100%"
        />
      )}

      <CardContent
        sx={{
          display: "flex",
          justifyContent: "space-between",
          px: 3,
        }}
      >
        <div>
          <Typography
            color={isPastEvent ? "textSecondary" : "textPrimary"}
            sx={{ mb: 1 }}
            variant="body1Semibold"
          >
            {isPastEvent ? "Past" : "Upcoming"}
          </Typography>
          <Typography color="textPrimary" variant="h3">
            {title}
          </Typography>
          <Box my={1}>
            <EventCardDate
              color={isPastEvent ? "textSecondary" : "textPrimary"}
              endDateTimeInUtc={endDateTimeInUtc}
              startDate={startDate}
              startDateTimeInUtc={startDateTimeInUtc}
              startTime={startTime}
              type={type}
            />
          </Box>

          <Truncate maxLines={3}>
            <Typography variant="body2">{description}</Typography>
          </Truncate>

          <Box mt={2}>
            {finalCtaLink && finalCtaText && (
              <Button
                className={ctaPendoClass}
                size="large"
                variant="contained"
                onClick={() => handleCtaClick(finalCtaLink)}
              >
                {finalCtaText}
              </Button>
            )}
          </Box>
        </div>

        {!isMobile && mediaUrl && (
          <div>
            <Image
              alt={mediaUrl}
              height="171px"
              objectFit="contain"
              src={mediaUrl}
              style={{
                borderRadius: "6px",
              }}
              width="304px"
            />
          </div>
        )}
      </CardContent>
    </Card>
  );
}
