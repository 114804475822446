/* eslint-disable camelcase */
import { Button, Divider } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { EnrichedActivity } from "getstream";
import { useRef } from "react";

import Reactions, {
  reactionOptions,
} from "components/feed/FeedCard/base/reactions";
import ModeComment from "components/icons/ModeComment";
import Snackbar from "components/shared/Snackbar";
import ReactionButton from "components/ui/ReactionButton";
import useReaction from "lib/motorcade/hooks/useReaction";
import { AlertCallback, ReactionCounts } from "lib/motorcade/types";

type Props = {
  activity: EnrichedActivity;
  customAction?: React.ReactNode;
  hideComment?: boolean;
  onCommentClick: () => void;
};

export default function ActivityCardActions({
  activity,
  customAction = null,
  hideComment = false,
  onCommentClick,
}: Props) {
  const snackbarRef = useRef(null);
  const { reaction_counts, latest_reactions } = activity;

  const filteredReactionCounts = { ...reaction_counts };
  const filteredLatestReactions = { ...latest_reactions };
  Object.keys(filteredReactionCounts).forEach((key) => {
    if (!Object.keys(reactionOptions).includes(key)) {
      delete filteredReactionCounts[key];
      delete filteredLatestReactions[key];
    }
  });

  const alertCallback: AlertCallback = ({ message, type, title }) => {
    snackbarRef?.current?.callSnackbar(message, type, title);
  };

  const { myReaction, addReaction, removeReaction } = useReaction(
    activity,
    alertCallback
  );

  const handleReactionClick = async (kind: string) => {
    myReaction && myReaction.kind === kind
      ? await removeReaction()
      : await addReaction(kind);
  };

  return (
    <>
      <Snackbar reference={snackbarRef} />
      <Stack spacing={1}>
        <Reactions
          commentCount={reaction_counts?.comment}
          latestReactions={filteredLatestReactions}
          reactionCounts={filteredReactionCounts as ReactionCounts}
        />

        <Divider />

        <Box display="flex" gap={1}>
          <ReactionButton
            pendoClassName="pendo_button-post-react"
            selectedReaction={myReaction?.kind}
            onClick={handleReactionClick}
          />

          {!hideComment && (
            <Button
              className="pendo_web-comment"
              startIcon={<ModeComment />}
              onClick={onCommentClick}
            >
              Comment
            </Button>
          )}

          {customAction && customAction}
        </Box>
      </Stack>
    </>
  );
}
