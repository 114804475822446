import { ArrowForward } from "@mui/icons-material";
import {
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
} from "@mui/material";
import { useState } from "react";

import { ActivityEditorData } from "components/feed/ActivityEditor/ActivityEditorDialog";
import DialogHeader from "components/shared/DialogHeader";
import { Audience as AudienceType } from "lib/motorcade/types";
// @todo update with feed group graph type
import { Group } from "types/group";

import GroupAudienceDialog from "./GroupAudienceDialog";
import { audienceOptions } from "./index";

type Props = {
  activityEditorData: ActivityEditorData;
  handleSaveAudience: (newAudience: AudienceType) => void;
  handleSaveGroupAudience: (newGroupAudience: Group) => void;
  onClose: () => void;
  excludeOptions: string[];
};

export default function AudienceDialogContent({
  activityEditorData,
  handleSaveAudience,
  handleSaveGroupAudience,
  onClose,
  excludeOptions,
}: Props) {
  const { audience } = activityEditorData;
  const [selectedAudience, setSelectedAudience] = useState(audience);
  const [groupAudienceDialogOpen, setGroupAudienceDialogOpen] = useState(false);

  return (
    <>
      <DialogHeader title="Who can see this post?" onClose={onClose} />

      <DialogContent dividers sx={{ px: "14px", py: "12px" }}>
        <List disablePadding>
          {Object.keys(audienceOptions).map((opt: AudienceType) => {
            if (excludeOptions.includes(opt)) return null;
            const isGroupAudience = audienceOptions[opt].audience === "GROUP";
            return (
              <ListItem
                secondaryAction={
                  isGroupAudience ? (
                    <IconButton edge="end" size="large">
                      <ArrowForward color="primary" />
                    </IconButton>
                  ) : (
                    <Radio checked={selectedAudience === opt} edge="end" />
                  )
                }
                sx={{ py: 1, px: 0 }}
                onClick={() => {
                  setSelectedAudience(opt);

                  if (isGroupAudience) {
                    setGroupAudienceDialogOpen(true);
                  }
                }}
              >
                <ListItemButton>
                  <ListItemIcon sx={{ minWidth: "47px" }}>
                    {audienceOptions[opt].icon}
                  </ListItemIcon>

                  <ListItemText
                    primary={audienceOptions[opt].label}
                    secondary={audienceOptions[opt].description}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          onClick={() => handleSaveAudience(selectedAudience)}
        >
          Save Changes
        </Button>
      </DialogActions>

      <GroupAudienceDialog
        activityEditorData={activityEditorData}
        groupType={selectedAudience === "group" ? "PUBLIC" : "PRIVATE"}
        handleSaveGroupAudience={handleSaveGroupAudience}
        open={groupAudienceDialogOpen}
        onClose={() => setGroupAudienceDialogOpen(false)}
      />
    </>
  );
}
