import { Avatar, Box, Typography, styled } from "@mui/material";
import { useEffect, useState } from "react";

import Truncate from "components/shared/Truncate";

import baseUrl from "lib/site/baseUrl";
import bizjournalsQueryParamInjector from "lib/site/bizjournalsQueryParamInjector";

const LeftBorder = styled("div")(() => ({
  flexShrink: 0,
  width: "4px",
  backgroundColor: "lightgray",
  borderRadius: "8px",
  marginRight: "10px",
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  marginRight: theme.spacing(1),
  maxWidth: "30px",
  maxHeight: "30px",
}));

function MetadataImage({ data }) {
  const urlMatchRegex =
    /(http|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/g;
  let imageUrl = `${data.image}`;
  if (!imageUrl.match(urlMatchRegex)) {
    imageUrl = `${data.url}${imageUrl}`;
  }

  return (
    <img
      alt={data.url}
      src={imageUrl}
      style={{ marginTop: "10px", width: "auto", maxHeight: "50px" }}
      width="100"
    />
  );
}

export default function PostUrlUnfurlerator({ text = "", max = 5 }) {
  const [urlMetadata, setUrlMetadata] = useState([]);

  useEffect(() => {
    const getUrlMetadata = async () => {
      const urlMatchRegex =
        /(http|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/g;
      let urlMatches = text.match(urlMatchRegex);
      let metadata;

      if (urlMatches) {
        // Remove dupes from urlMatches
        urlMatches = [...new Set(urlMatches)];
        // Slim matches down to first 5
        urlMatches = urlMatches.slice(0, max);

        let messageUrlMetadata = await Promise.all(
          urlMatches.map(async (url) => {
            return fetch(`${baseUrl("/api/url-metadata")}?url=${url}`).then(
              (res) => res.json()
            );
          })
        );

        //   Filter out errors
        messageUrlMetadata = messageUrlMetadata
          .filter((item) => item.data)
          .map((item) => {
            const { data } = item;
            if (data.url.match("bizjournals.com")) {
              data.url = bizjournalsQueryParamInjector(data.url);
            }
            if (data.title?.match("&amp;038;")) {
              data.title = data.title.replaceAll("&amp;038;", "&");
            }
            return data;
          });

        metadata = messageUrlMetadata;
      } else {
        metadata = [];
      }
      setUrlMetadata(metadata);
    };
    getUrlMetadata();
  }, [max, text]);

  const UnfurledUrls = () => {
    return urlMetadata.map((data) => {
      if (data.error) return null;

      return (
        <Box
          key={`${data.url}-${Math.random()}`}
          alignItems="stretch"
          display="flex"
          p="12px 0px"
          position="relative"
        >
          <LeftBorder />
          <Box>
            <Box alignItems="center" display="flex">
              {data.icon && <StyledAvatar src={data.icon} variant="rounded" />}{" "}
              {data.provider && <Typography>{data.provider}</Typography>}
            </Box>
            {data.url && data.title && (
              <Truncate maxLines={2}>
                <Typography>
                  <a href={data.url} rel="noreferrer" target="_blank">
                    {data.title}
                  </a>
                </Typography>
              </Truncate>
            )}
            {data.description && (
              <Truncate maxLines={2}>
                <div style={{ fontSize: "14px" }}>{data.description}</div>
              </Truncate>
            )}
            {data.image && <MetadataImage data={data} />}
          </Box>
        </Box>
      );
    });
  };

  return <UnfurledUrls />;
}
