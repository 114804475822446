import Image from "components/shared/Image";
import { UrlMetadata } from "lib/motorcade/types";

type Props = {
  data: UrlMetadata;
  publishedUrl?: string;
};

export default function MediaContentImage({ data, publishedUrl }: Props) {
  const urlMatchRegex =
    /(http|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/g;
  let imageUrl = `${data.image}`;
  if (!imageUrl.match(urlMatchRegex)) {
    imageUrl = `${data.url}${imageUrl}`;
  }

  return (
    <a href={publishedUrl ?? data.url} rel="noreferrer" target="_blank">
      <Image
        externalSrc
        priority
        alt={data.url}
        // Temporarily disabling sizes prop as it's using matchAll which breaks old iOS versions
        // sizes="(max-width: 400px) 414px, (max-width: 768px) 540px, 473px"
        height={296}
        objectFit="cover"
        objectPosition="center"
        src={imageUrl}
        style={{ borderRadius: "6px" }}
        width={473}
      />
    </a>
  );
}
