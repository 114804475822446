import { Skeleton } from "@mui/material";
import { Box, Stack } from "@mui/system";

import theme from "styles/theme";

export default function ResponseSkeleton() {
  return (
    <Box display="flex" gap={1.5}>
      {/* The div wrapper prevents the avatar skeleton from getting squashed */}
      <div>
        <Skeleton height={40} variant="circular" width={40} />
      </div>
      <Stack spacing={1} width="100%">
        <Stack
          borderRadius={1}
          p={2}
          spacing={1}
          sx={{
            backgroundColor: theme.palette.grey[100],
          }}
          width="100%"
        >
          <div>
            <Skeleton
              sx={{ fontSize: theme.typography.body2.fontSize }}
              variant="text"
              width={150}
            />
            <Skeleton
              sx={{ fontSize: theme.typography.caption.fontSize }}
              variant="text"
              width={200}
            />
          </div>

          <div>
            <Skeleton
              sx={{ fontSize: theme.typography.body2.fontSize }}
              variant="text"
              width="100%"
            />
            <Skeleton
              sx={{ fontSize: theme.typography.body2.fontSize }}
              variant="text"
              width="50%"
            />
          </div>
        </Stack>
      </Stack>
    </Box>
  );
}
