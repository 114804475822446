type Props = {
  groupId: string;
  groupsILead: {
    groupId: string;
    role: "FULL" | "LIMITED";
    capabilities: string[];
  }[];
};

export default function canPinToGroup({ groupsILead, groupId }: Props) {
  if (!groupsILead || groupsILead?.length === 0) return false;

  const groupILead = groupsILead.find((group) => group.groupId === groupId);
  if (groupILead) {
    if (
      groupILead.role === "FULL" ||
      (groupILead.role === "LIMITED" &&
        groupILead.capabilities.includes("PIN_POSTS"))
    ) {
      return true;
    }
  }

  return false;
}
