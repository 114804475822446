import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";

import DialogHeader from "components/shared/DialogHeader";

type Props = {
  open: boolean;
  isEditing: boolean;
  onCancel: () => void;
  onDiscard: () => void;
};

export default function DiscardDialog({
  open,
  isEditing,
  onCancel,
  onDiscard,
}: Props) {
  return (
    <Dialog open={open} sx={{ maxWidth: "392px", margin: "0 auto" }}>
      <DialogHeader
        title={isEditing ? "Discard Changes?" : "Discard Draft?"}
        onClose={onCancel}
      />

      <DialogContent sx={{ pb: 1.5 }}>
        <Typography color="grey.600">
          {isEditing
            ? "Are you sure you want to discard your changes?"
            : "Are you sure you want to discard your draft? Your post will not be saved."}
        </Typography>
      </DialogContent>

      <DialogActions sx={{ pt: 0, pb: 2.5, px: 3 }}>
        <Button onClick={onCancel}>Go Back</Button>
        <Button variant="contained" onClick={onDiscard}>
          Discard
        </Button>
      </DialogActions>
    </Dialog>
  );
}
