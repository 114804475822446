/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CollectionResponse,
  EnrichedActivity,
  EnrichedReaction,
  EnrichedUser,
} from "getstream";

import Celebration from "components/icons/Celebration";
import Concerned from "components/icons/Concerned";
import Lightbulb from "components/icons/Lightbulb";
import ThumbUpAlt from "components/icons/ThumbUpAlt";

export type FeedGroup =
  | "agg_timeline"
  | "public"
  | "user"
  | "group"
  | "group_pin";
export type Audience = "general" | "profile" | "group";
export type EventType = "IN_PERSON" | "HYBRID" | "VIRTUAL";

export type Verb =
  | "article"
  | "community_expertpanel_answer"
  | "event"
  | "expertpanel"
  | "follow"
  | "group_expertpanel"
  | "join"
  | "poll"
  | "post"
  | "thirdparty";

export enum GroupType {
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC",
}

export type CcoReaction = "LIKE" | "CELEBRATE" | "INSIGHTFUL" | "CONCERNED";

export type GraphUser = {
  node: {
    id: string;
    snapshot: {
      name: string;
      avatar: string;
      companyName: string;
    };
  };
};

export type StreamUser = EnrichedUser & {
  data: {
    id: string;
    avatar: string;
    company: string;
    name: string;
    title: string;
  };
  collection?: string;
};

export type StreamReaction = EnrichedReaction & {
  id: string;
  user: StreamUser;
  user_id: string;
};

export type AnswerReaction = StreamReaction & {
  id: string &
    (
      | {
          kind: "answer";
          data: {
            body: string;
            redirectUrl: string;
          };
        }
      | {
          kind: "community_expertpanel_answer";
          data: {
            body: string;
            foreign_id: string;
            redirectUrl: string;
          };
        }
    );
};

export type VoteReaction = StreamReaction & {
  id: string;
  kind: "vote";
  data: {
    choices: string[];
  };
};

export type CommentReaction = StreamReaction & {
  id: string;
  kind: "comment";
  data: {
    body: string;
    mentions: string[];
    redirectUrl: string;
    mediaUrl: string;
    mediaType: "video" | "image";
  };
};

export type ReplyReaction = StreamReaction & {
  id: string;
  kind: "reply";
  data: {
    body: string;
    mentions: string[];
    redirectUrl: string;
    mediaUrl: string;
    mediaType: "video" | "image";
  };
};

export type ReactionIcon =
  | typeof ThumbUpAlt
  | typeof Celebration
  | typeof Lightbulb
  | typeof Concerned;

export interface ReactionOption {
  label: CcoReaction;
  icon: ReactionIcon;
  color: string;
  backgroundColor: string;
}

export type ReactionCounts = {
  like?: number;
  insightful?: number;
  celebrate?: number;
  concerned?: number;
};

export type LatestReactions = {
  answer?: AnswerReaction[];
  celebrate?: StreamReaction[];
  comment?: CommentReaction[];
  community_expertpanel_answer?: AnswerReaction[];
  concerned?: StreamReaction[];
  insightful?: StreamReaction[];
  like?: StreamReaction[];
  vote?: VoteReaction[];
};

export type Activity = {
  id: string;
  actor?: {
    id: string;
    data: {
      name: string;
      avatar: string;
    };
  };
  audience: string;
  groupRef?: {
    id: string;
    data: {
      image: string;
      name: string;
      slug: string;
      type: GroupType;
    };
  };
  object: {
    data: {
      body: string;
    };
  };
  time: string;
  verb: Verb;
};

export type EventCollection = CollectionResponse & {
  data: {
    audience: Audience;
    cta: string;
    description: string;
    link: string;
    resourceCta: string;
    resourceLink: string;
    startTime: string;
    startDate: string;
    timezone: string;
    title: string;
    endTime?: string;
    type: EventType;
    hostId?: string;
    groupId?: string;
  };
};

export type GroupCollection = CollectionResponse & {
  data: {
    id: string;
    name: string;
    description: string;
    image?: string;
    slug: string;
    type: GroupType;
    createdAt: string;
  };
};

export type PostCollection = CollectionResponse & {
  data: {
    body: string;
  };
};

export type PollCollection = CollectionResponse & {
  data: {
    audience: Audience;
    closeAt: string;
    isUpdated: boolean;
    numberOfParticipants: string;
    options: string[];
    postText: string;
    question: string;
    results: number[];
    type: "SINGLE" | "MULTI";
    deleted?: boolean;
  };
};

export type ArticleCollection = CollectionResponse & {
  data: {
    id: string;
    url: string;
    author: StreamUser;
    editorsChoice?: boolean;
  };
};

export type ExpertPanelRoundupCollection = CollectionResponse & {
  data: {
    id: string;
    url: string;
    authorIds: string[];
  };
};

export type GroupExpertPanelCollection = CollectionResponse & {
  data: {
    endAt: string;
    isEnded: boolean;
    maxAnswerLength: number;
    question: string;
    questionId: string;
    releaseAt: string;
  };
};

export type ExpertPanelQuestionCollection = CollectionResponse & {
  collection: "expertpanel";
  id: string;
  data: {
    endAt: string;
    isEnded: boolean;
    maxAnswerLength: number;
    maxAnswers: number;
    question: string;
    questionId: string;
    releaseAt: string;
    topicTagId: string;
    topicTagLabel: string;
  };
};

export type CommunityExpertPanelCollection = EnrichedActivity & {
  actor: "string";
  latest_reactions: LatestReactions;
  reaction_counts: ReactionCounts;
  object: ExpertPanelQuestionCollection;
};

export type FeedActivity = EnrichedActivity & {
  actor: StreamUser;
  audience: Audience;
  groupRef?: GroupCollection;
  mediaUrl?: string;
  mediaType?: string;
  host?: StreamUser;
  activities?: FeedActivity[];
  latest_reactions: LatestReactions;
} & (
    | {
        verb: "post" | "thirdparty";
        object: PostCollection;
      }
    | {
        verb: "poll";
        object: PollCollection;
      }
    | {
        verb: "event";
        object: EventCollection;
      }
    | {
        verb: "article";
        object: ArticleCollection;
      }
    | {
        verb: "expertpanel";
        object: ExpertPanelRoundupCollection;
      }
    | {
        verb: "follow";
        object: StreamUser;
      }
    | {
        verb: "join";
        object: GroupCollection;
      }
    | {
        verb: "group_expertpanel";
        object: GroupExpertPanelCollection;
      }
    | {
        verb: "community_expertpanel_answer";
        object: CommunityExpertPanelCollection;
        reaction: AnswerReaction;
      }
  );

export function isFeedActivity(value: any): value is FeedActivity {
  if (value?.verb) {
    return true;
  }
  return false;
}

export type UrlMetadata = {
  image: string;
  title: string;
  description: string;
  provider: string;
  url: string;
};

export type PostContent = {
  content: string;
  mediaFile?: string | File;
  mediaType?: string;
};

export type AlertCallback = ({
  message,
  type,
  title,
}: {
  message?: string;
  type: "error" | "success";
  title?: string;
}) => void;

export type EnrichedResultActivity =
  | EnrichedActivity
  | readonly EnrichedActivity[];
