import { Box, DialogContent, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useRef, useState } from "react";

import { ActivityEditorData } from "components/feed/ActivityEditor/ActivityEditorDialog";
import Audience from "components/feed/ActivityEditor/Audience";
import PostEditorMenu from "components/feed/ActivityEditor/editors/PostEditorMenu";
import PollPreview from "components/feed/ActivityEditor/PollPreview";
import Avatar from "components/shared/Avatar";
import DialogHeader from "components/shared/DialogHeader";
import { EditorViewRef } from "components/shared/inputs/Editor";
import LinkForm from "components/shared/inputs/Editor/forms/LinkForm";
import {
  docHasContent,
  getContentAsMarkdown,
} from "components/shared/inputs/Editor/helpers";
import autoLink from "components/shared/inputs/Editor/plugins/autoLink";
import editLink from "components/shared/inputs/Editor/plugins/editLink";
import placeholder from "components/shared/inputs/Editor/plugins/placeholder";
import suggestions from "components/shared/inputs/Editor/plugins/suggestions";
import watch from "components/shared/inputs/Editor/plugins/watch";
import useEditor from "components/shared/inputs/Editor/useEditor";
import SocialEditor from "components/shared/inputs/SocialEditor";
import MediaAttachmentPreview, {
  MediaAttachmentData,
} from "components/ui/MediaAttachmentPreview";
import useMe from "hooks/useMe";
import { useApp } from "lib/common/appProvider";
import parseMentions from "lib/markdown/parseMentions";
import { useFileUploader } from "lib/motorcade/hooks/useFileUploader";
import usePoll from "lib/motorcade/hooks/usePoll";
import { usePost } from "lib/motorcade/hooks/usePost";
import {
  AlertCallback,
  FeedActivity,
  PostCollection,
} from "lib/motorcade/types";
import { getAudienceLabel } from "lib/motorcade/utils/helpers";

type Props = {
  activity?: FeedActivity;
  activityEditorData: ActivityEditorData;
  setActivityEditorData: Dispatch<SetStateAction<ActivityEditorData>>;
  onClose: () => void;
  onSubmit: () => void;
  setIsDirty: (value: boolean) => void;
  setActiveEditorDialog: Dispatch<SetStateAction<"post" | "poll" | "event">>;
  canSelectAudience: boolean;
};

export default function PostEditorDialog({
  activity,
  activityEditorData,
  setActivityEditorData,
  setActiveEditorDialog,
  onClose,
  onSubmit,
  setIsDirty,
  canSelectAudience,
}: Props) {
  const snackbarRef = useApp("snackbarRef");
  const alertCallback: AlertCallback = ({ message, type, title }) => {
    snackbarRef?.current?.callSnackbar(message, type, title);
  };

  const isEditing = !!activity;
  const { addPost, addPostLoading, updatePost } = usePost({
    activity,
    alertCallback,
  });
  const { addPoll, addPollLoading, updatePoll } = usePoll(
    activity,
    alertCallback
  );
  const { name, profile } = useMe();
  const { uploadFile, isUploadingFile } = useFileUploader();
  const [mediaAttachmentData, setMediaAttachmentData] =
    useState<MediaAttachmentData | null>(
      activity?.mediaUrl && {
        url: activity?.mediaUrl,
        type: activity?.mediaType,
      }
    );

  const post = activity?.object as PostCollection;
  const poll = activityEditorData?.poll;
  const content = post?.data?.body || poll?.data?.postText || "";

  const [state, setState] = useEditor({
    content,
    plugins: [
      autoLink(),
      editLink(),
      placeholder({ text: "Share what's on your mind..." }),
      suggestions({ triggerCharacter: "@" }),
      watch({ onIsDirty: setIsDirty }),
    ],
  });

  const editorRef: EditorViewRef = useRef();
  const view = editorRef?.current?.view;

  const canSubmitPoll =
    (poll && !poll.data.deleted) ||
    (poll && poll.data.deleted && docHasContent(state.doc));
  const canSubmitPost = docHasContent(state.doc);
  const canSubmit = canSubmitPost || canSubmitPoll;
  const isSubmitting = isUploadingFile || addPostLoading || addPollLoading;

  const handleSubmitPost = async () => {
    const markdown = getContentAsMarkdown(state.doc);

    const input = {
      attachment: { url: "", mediaType: "" },
      markdown,
      mentionedUserIds: parseMentions(markdown),
    };

    if (mediaAttachmentData?.url) {
      input.attachment = {
        url: mediaAttachmentData.url,
        mediaType: mediaAttachmentData.type,
      };
    }

    // @todo make this optimistically display the file instead of waiting
    if (mediaAttachmentData?.file) {
      const { url, mediaType } = await uploadFile(mediaAttachmentData.file);
      input.attachment = { url, mediaType };
    }

    if (isEditing) {
      updatePost.mutate(input);
    } else {
      await addPost({
        ...input,
        group: activityEditorData?.group,
        audience: activityEditorData?.audience,
      });
    }

    onSubmit();
  };

  const handleSubmitPoll = async () => {
    const postText = getContentAsMarkdown(state.doc);

    if (isEditing) {
      const input = {
        deleted: poll.data.deleted,
        postText,
      };

      updatePoll(input);
    } else {
      const input = {
        postText,
        question: activityEditorData.poll.data.question,
        options: activityEditorData.poll.data.options,
        closeAt: activityEditorData.poll.data.closeAt,
        audience: activityEditorData?.audience,
        group: activityEditorData?.group,
      };

      await addPoll(input);
    }

    onSubmit();
  };

  const handleOpenPollEditor = () => {
    const postText = getContentAsMarkdown(state.doc);
    // Set the poll postText here to persist it between dialog changes
    setActivityEditorData((prevState) => ({
      ...prevState,
      poll: {
        ...prevState?.poll,
        data: { ...poll?.data, postText },
      } as ActivityEditorData["poll"],
    }));
    setActiveEditorDialog("poll");
  };

  const title = canSelectAudience ? "Create a post" : "Start a discussion";

  return (
    <>
      <DialogHeader
        canSubmit={canSubmit}
        isSubmitting={isSubmitting}
        title={isEditing ? "Edit post" : title}
        onClose={onClose}
        onSubmit={poll ? handleSubmitPoll : handleSubmitPost}
      />

      <DialogContent
        dividers
        sx={(theme) => ({
          padding: "21px 24px 16px 24px",

          [theme.breakpoints.down("sm")]: {
            padding: "16px 16px 20px",
          },
        })}
      >
        <Box alignItems="center" display="flex" gap={2} mb={1}>
          <Avatar alt={name?.fullName} src={profile?.avatar} />

          {isEditing ? (
            <Typography color="textPrimary" variant="body2">
              Posted to <strong>{getAudienceLabel(activity)}</strong>
            </Typography>
          ) : (
            <Audience
              activityEditorData={activityEditorData}
              disabled={!canSelectAudience}
              setActivityEditorData={setActivityEditorData}
              setIsDirty={setIsDirty}
            />
          )}
        </Box>

        <SocialEditor ref={editorRef} setState={setState} state={state} />

        <LinkForm setState={setState} state={state} view={view} />

        <MediaAttachmentPreview
          attachmentData={mediaAttachmentData}
          onDelete={() => {
            setMediaAttachmentData(null);
            // @todo come up with a better way to set the dirty state
            // as this won't always be accurate e.g. if the user types,
            // then adds an image and then removes it the dirty state
            // would be incorrect
            setIsDirty(false);
          }}
        />

        {poll && (
          <PollPreview
            handleOpenPollEditor={handleOpenPollEditor}
            isEditing={isEditing}
            poll={poll}
            setActivityEditorData={setActivityEditorData}
            setIsDirty={setIsDirty}
          />
        )}

        <PostEditorMenu
          activityEditorData={activityEditorData}
          canSubmit={canSubmit}
          handleOpenPollEditor={handleOpenPollEditor}
          hasMediaAttachment={!!mediaAttachmentData}
          hasPoll={!!poll}
          isEditing={isEditing}
          isSubmitting={isSubmitting}
          setActiveEditorDialog={setActiveEditorDialog}
          setIsDirty={setIsDirty}
          setMediaAttachmentData={setMediaAttachmentData}
          setState={setState}
          state={state}
          view={view}
          onSubmit={poll ? handleSubmitPoll : handleSubmitPost}
        />
      </DialogContent>
    </>
  );
}
