import { Autocomplete, TextField } from "@mui/material";
import { getTimeZones } from "@vvo/tzdb";

const timeZones = getTimeZones();

const buildOptionLabel = (option) => {
  const offset = option.rawFormat.split(" ")[0];
  let label = `(GMT${offset}) ${option.alternativeName}`;

  if (
    timeZones.filter(
      (timeZone) => timeZone.alternativeName === option.alternativeName
    ).length > 1
  ) {
    label += ` - ${option.mainCities[0]}`;
  }

  return label;
};

export function TimeZoneSelect({ error = false, helperText = "", ...params }) {
  return (
    <Autocomplete
      filterOptions={(options, { inputValue }) =>
        options.filter(
          (option) =>
            option.abbreviation
              .toLowerCase()
              .includes(inputValue.toLowerCase()) ||
            option.alternativeName
              .toLowerCase()
              .includes(inputValue.toLowerCase()) ||
            option.continentName
              .toLowerCase()
              .includes(inputValue.toLowerCase()) ||
            option.countryName
              .toLowerCase()
              .includes(inputValue.toLowerCase()) ||
            option.mainCities
              .join(", ")
              .toLowerCase()
              .includes(inputValue.toLowerCase())
        )
      }
      getOptionLabel={(option) => buildOptionLabel(option)}
      options={timeZones}
      renderInput={(inputParams) => (
        <TextField
          {...inputParams}
          error={error}
          helperText={error && helperText}
          placeholder="Select a timezone"
        />
      )}
      {...params}
    />
  );
}
