import { FeedActivity } from "../types";

const verbsWithoutAudienceLabel = [
  "article",
  "community_expertpanel_answer",
  "expertpanel",
  "group_expertpanel",
];

export function getAudienceLabel({
  audience,
  groupRef,
  object,
  verb,
}: FeedActivity) {
  if (verbsWithoutAudienceLabel.includes(verb)) return null;
  if (verb === "join" || verb === "follow") return object.data.name;
  if (audience?.toLowerCase() === "group") return groupRef?.data.name;
  return audience === "profile" ? "Followers" : "Everyone";
}
