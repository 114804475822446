import { DateRange } from "@mui/icons-material";
import { Chip, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { format, isValid } from "date-fns";

import { EventType } from "lib/motorcade/types";

type Props = {
  startDateTimeInUtc: Date;
  endDateTimeInUtc: Date | null;
  startDate: string;
  type: EventType;
  startTime: string;
  color: TypographyColors;
};

const EVENT_TYPE_LABEL = {
  IN_PERSON: "In Person",
  HYBRID: "Hybrid",
  VIRTUAL: "Virtual",
};

const formatEventDate = (date: number | Date) =>
  isValid(date) ? format(date, "iii, LLL d, yyyy") : "";

const formatEventTime = (start: number | Date, end: number | Date | null) => {
  const startTime = isValid(start) ? format(start, "hh:mm a") : "";
  const endTime = end && isValid(end) ? ` to ${format(end, "hh:mm a")}` : "";
  return startTime + endTime;
};

export default function EventCardDate({
  startDateTimeInUtc,
  endDateTimeInUtc,
  startDate,
  type,
  startTime,
  color,
}: Props) {
  const formattedDate = formatEventDate(startDateTimeInUtc);
  const formattedTime = formatEventTime(startDateTimeInUtc, endDateTimeInUtc);

  return (
    <Box>
      {startDate && (
        <Box alignItems="center" display="flex">
          <DateRange sx={{ color: "#808080" }} />
          <Typography color={color} sx={{ ml: 0.5 }} variant="body2Semibold">
            {formattedDate}
          </Typography>
        </Box>
      )}
      {startTime && (
        <Typography color={color} variant="body2Semibold">
          {formattedTime}
        </Typography>
      )}
      <Chip
        label={EVENT_TYPE_LABEL[type]}
        size="small"
        sx={{ mt: 1, backgroundColor: "grey.300" }}
      />
    </Box>
  );
}
