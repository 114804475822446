import { Backdrop, Box } from "@mui/material";
import { useState } from "react";

import theme from "styles/theme";

type Props = {
  type: "image" | "video" | undefined;
  image: string | File | undefined;
  isPinned?: boolean;
};

export default function MediaAttachmentDisplay({
  type,
  image,
  isPinned,
}: Props) {
  const [isFull, setIsFull] = useState(false);

  if (!image) return null;

  const displayedImage =
    image instanceof File ? URL.createObjectURL(new Blob([image])) : image;
  const alt = image instanceof File ? image.name : image;

  return (
    <>
      {type?.includes("image") && (
        <Box
          sx={{
            ...(isPinned && {
              width: "100%",
              overflow: "hidden",
              "& img": {
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "center",
              },
            }),
          }}
        >
          <Box width="fit-content" onClick={() => setIsFull(true)}>
            <img
              alt={alt}
              src={displayedImage}
              style={{
                ...(!isPinned && {
                  width: "100%",
                  height: "auto",
                  objectFit: "cover",
                  objectPosition: "center",
                  borderRadius: "5px",
                  maxHeight: "350px",
                  maxWidth: "350px",
                  cursor: "pointer",

                  [theme.breakpoints.up("sm")]: {
                    width: "auto",
                    height: "auto",
                  },
                }),
              }}
            />
          </Box>
        </Box>
      )}
      {type?.includes("video") && (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video controls height="300">
          <source src={displayedImage} />
        </video>
      )}
      <Backdrop
        open={isFull}
        sx={{ zIndex: theme.zIndex.drawer + 2 }}
        onClick={() => setIsFull(false)}
      >
        <Box
          height="100vh"
          px={{ xs: 2, md: 12 }}
          py={{ xs: 2, md: 4 }}
          width="100vw"
        >
          <img
            alt={alt}
            src={displayedImage}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </Box>
      </Backdrop>
    </>
  );
}
